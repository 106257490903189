import React from "react";

const CardVariantListing = ({ data, title = "", subTitle = "" }) => {
  return (
    <div className="card-variant-listing">
      <div className="card-temp-header">
        <p className="card-cat">{title}</p>

        <p className="card-art">{subTitle}</p>
      </div>
      <div className="card-temp-content">
        {data.map(({ label, value }) => (
          <div className="ctv-row" key={label}>
            <p className="ctv-row-label">{label}</p>
            <p className="ctv-row-value">{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardVariantListing;
