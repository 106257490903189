import { Col, Row } from "antd";
import {
  CALENDER_ICON_CARD,
  EYE_ICON,
  LIST_NUMBER_ICON,
  THUMB_ICON,
} from "assets";
import { CardVariant1 } from "global/components";
import moment from "moment";
import { fixPointNumber } from "utils";

const StatisticsListingSection = ({ statistics }) => {
  return (
    <Row gutter={[30, 20]} className="section-margin-top">
      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={LIST_NUMBER_ICON}
          number={statistics?.quantity}
          title="Quantity"
        />
      </Col>
      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={THUMB_ICON}
          number={statistics?.num_favorers}
          title="Likes"
        />
      </Col>
      {/* <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={DOLLAR_ICON}
          number={statistics?.price}
          title="Price"
        />
      </Col> */}

      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={EYE_ICON}
          number={statistics?.views}
          title="Views"
        />
      </Col>

      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={EYE_ICON}
          number={fixPointNumber(statistics?.monthly_views, 2)}
          title="Monthly Views"
        />
      </Col>
      {statistics?.last_modified && (
        <Col xs={24} sm={12} md={8}>
          <CardVariant1
            icon={CALENDER_ICON_CARD}
            number={moment
              .unix(statistics?.last_modified)
              .format("DD MMM YYYY")}
            title="Last Modified"
          />
        </Col>
      )}
      {statistics?.creation_time && (
        <Col xs={24} sm={12} md={8}>
          <CardVariant1
            icon={CALENDER_ICON_CARD}
            number={moment
              .unix(statistics?.creation_time)
              .format("DD MMM YYYY")}
            title="Create"
          />
        </Col>
      )}
      {statistics?.expires_on && (
        <Col xs={24} sm={12} md={8}>
          <CardVariant1
            icon={CALENDER_ICON_CARD}
            number={moment.unix(statistics?.expires_on).format("DD MMM YYYY")}
            title="Expires"
          />
        </Col>
      )}
      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={CALENDER_ICON_CARD}
          number={fixPointNumber(statistics?.age, 2)}
          title="Age"
        />
      </Col>
    </Row>
  );
};

export default StatisticsListingSection;
