import { HeaderSection, InfoSection } from "./components";
import "./styles.scss";
import useProfile from "./useProfile";

const Profile = () => {
  const {
    uploadInputRef,
    handleFileSelect,
    handleAccountDelete,
    deleteAccountApiLoading,
    handleUpdateProfile,
    updateProfileApiLoading,
    selectedImageUrl,
    user,
    handleCancelSubscription,
    cancelSubscriptionApiLoading,
  } = useProfile();

  return (
    <div className="dashboard-home-wrapper w-100">
      <HeaderSection
        uploadInputRef={uploadInputRef}
        handleFileSelect={handleFileSelect}
        handleAccountDelete={handleAccountDelete}
        handleCancelSubscription={handleCancelSubscription}
        cancelSubscriptionApiLoading={cancelSubscriptionApiLoading}
        deleteAccountApiLoading={deleteAccountApiLoading}
        selectedImageUrl={selectedImageUrl}
      />
      <InfoSection
        handleUpdateProfile={handleUpdateProfile}
        updateProfileApiLoading={updateProfileApiLoading}
        user={user}
      />
    </div>
  );
};

export default Profile;
