import { ArrowRightOutlined } from "@ant-design/icons";
import { Drawer, Grid, Layout } from "antd";
import { HAMBURGER_ICON } from "assets";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { mainMenuItems } from "./menuItems";
import useSidebar from "./useSidebar";
import SubscriptionInfo from "../SubscriptionInfo";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const Sidebar = ({ disabled }) => {
  const { collapsed, handleCollapse, handleGoToHome } = useSidebar();
  const screens = useBreakpoint();

  if (screens.lg === undefined) {
    return <></>;
  }

  const sidebarContent = (
    <>
      <div className={"dashboard-sidebar-top"}>
        <div className="app-logo" onClick={handleGoToHome} />
        <ReactSVG
          src={HAMBURGER_ICON}
          className="humburger"
          onClick={handleCollapse}
        />
      </div>

      <div className={"dashboard-sidebar-menu"}>
        {mainMenuItems.map(({ title, to, icon }) => (
          <NavLink
            className={({ isActive }) =>
              `ds-menu-item ${isActive ? "active" : ""} ${
                disabled ? "disabled" : ""
              }`
            }
            key={title}
            to={to}
            end
          >
            <div className="ds-icon-wrapper">{icon}</div>
            <AnimatePresence>
              {!collapsed && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {title}
                </motion.p>
              )}
            </AnimatePresence>
          </NavLink>
        ))}
        {!collapsed && <SubscriptionInfo />}
      </div>
    </>
  );

  return screens.lg ? (
    <Sider
      trigger={null}
      collapsible
      theme="light"
      collapsed={collapsed}
      width={230}
      className={`dashboard-sidebar  ${collapsed ? "collapsed" : ""}`}
    >
      {sidebarContent}
    </Sider>
  ) : (
    <>
      <SiderToggler
        isLg={false}
        isOpen={!collapsed}
        onToggle={handleCollapse}
      />
      <Drawer
        title={null}
        closeIcon={null}
        width={250}
        className={"dashboard-sidebar"}
        placement="left"
        onClose={handleCollapse}
        open={!collapsed}
      >
        {sidebarContent}
      </Drawer>
    </>
  );
};

const SiderToggler = ({ onToggle, isOpen, isLg }) => (
  <div
    className={`${isOpen ? "opened" : "closed"} sider-toggler ${
      isLg ? "is-lg" : ""
    } `}
    onClick={onToggle}
  >
    <ArrowRightOutlined />
  </div>
);

export default Sidebar;
