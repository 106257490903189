const AXIOS_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const LOCAL_STORAGE_VALUES = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  PASSWORD_ACCESS_TOKEN: "password_access_token",
};

const STRIPE_PAYMENT_STATUS = {
  ACTIVE: "active", // The subscription is current and active.
  TRIAL: "trial", // The subscription is in a trial period.
  PAST_DUE: "past_due", // A payment is due but hasn't been paid.
  INCOMPLETE: "incomplete", // The initial payment attempt for the subscription failed.
  INCOMPLETE_EXPIRED: "incomplete_expired", // The subscription was not activated within the required time and is now expired.
  CANCELED: "canceled", // The subscription was canceled.
  UNPAID: "unpaid", // The subscription is active but unpaid. This status may not directly map to Stripe's statuses but can be useful for internal tracking.
  NO_SUBSCRIPTION: "no_subscription",
};

export { AXIOS_METHODS, LOCAL_STORAGE_VALUES, STRIPE_PAYMENT_STATUS };
