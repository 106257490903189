import { Avatar, Dropdown } from "antd";
import useLayout from "context/Layout/useLayout";
import useHeader from "./useHeader";
import { UserOutlined } from "@ant-design/icons";

const Header = ({ isPageTop }) => {
  const { activeHeading } = useLayout();
  const { userOptions, user } = useHeader();

  return (
    <header className={`dashboard-header ${isPageTop ? "top" : ""}`}>
      <h3 className="m-0">{activeHeading}</h3>
      <Dropdown menu={{ items: userOptions }}>
        <div className="dashboard-header-right">
          <div className="user-info">
            <h5 className="m-0">{user?.name}</h5>
            <p className="text-small m-0">{user?.email}</p>
          </div>
          <Avatar
            size={"large"}
            src={user?.image_url}
            icon={<UserOutlined />}
          ></Avatar>
        </div>
      </Dropdown>
    </header>
  );
};

export default Header;
