import React from 'react'
import { ReactSVG } from "react-svg";

const InfoCard = ({icon , data, title}) => {
    return (
        <div className='info-card'>

            <div className='ic-left'>
            <ReactSVG src={icon} alt="" />
            </div>


            <div className='ic-right'>
               <h3> {data}</h3>
             <p>{title}</p>
            </div>


        </div>

        //  <div className='card-variant-1'>
        //   <div className='cv-1-left'>
        //     <h3> {'number'}</h3>
        //     <p>{'title'}</p>
        //   </div>
        //   <div className='cv-1-right'>
        //     <img src={''} alt="" />
        //   </div>
        // </div>
    )
}

export default InfoCard