import { Result } from "antd";
import useStripePaymentStatus from "./useStripePaymentStatus";

const StripePaymentStatus = () => {
  const { paymentStatus } = useStripePaymentStatus();

  let content;

  switch (paymentStatus) {
    case "succeeded":
      content = (
        <Result
          status="success"
          title="Payment Successful"
          subTitle="Your payment has been received."
          // You can add extra content or actions here if needed
        />
      );
      break;
    case "processing":
      content = (
        <Result
          status="info"
          title="Payment Processing"
          subTitle="Your payment is being processed. We'll update you once it's received."
        />
      );
      break;
    case "requires_payment_method":
      content = (
        <Result
          status="error"
          title="Payment Failed"
          subTitle="Payment failed. Please try another payment method."
        />
      );
      break;
    default:
      content = (
        <Result
          status="404"
          title="Waiting for Payment"
          subTitle="Please complete the payment process."
        />
      );
  }

  return <main className="stripe-payment-status-page-wrapper">{content}</main>;
};

export default StripePaymentStatus;
