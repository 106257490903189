import { useForm } from "antd/es/form/Form";
import { calculateProfitApiAxiosParams } from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import { useAxios } from "hooks";
import { useCallback } from "react";
import { fixPointNumber } from "utils";

const useDashboardCalculator = () => {
  const {
    loading,
    callAxios: callCalculateProfitApi,
    result: calculateProfitApiResult,
  } = useAxios(calculateProfitApiAxiosParams);

  const [form] = useForm();

  const handleCalculateProfit = useCallback(
    async (values) => {
      await callCalculateProfitApi({
        url: API_ENDPOINTS.CALCULATE_PROFIT(values),
      });
    },
    [callCalculateProfitApi]
  );

  const feeAndTaxes = [
    {
      label: "Payment Processing Fee (USD)",
      value: calculateProfitApiResult?.payment_processing_fee || "N/a",
    },
    {
      label: "Transaction Fee  (includes shipping)",
      value: calculateProfitApiResult?.transaction_fee || "N/a",
    },
    {
      label: "Maximum Shipping (USD)",
      value: calculateProfitApiResult?.cust_price || "N/a",
    },
  ];

  const estimatedProfit = [
    {
      label: "Price",
      value: fixPointNumber(calculateProfitApiResult?.cust_price, 2) || "N/a",
    },
    {
      label: "Total Cost",
      value: fixPointNumber(calculateProfitApiResult?.total_cost, 2) || "N/a",
    },
    {
      label: "Total Fees",
      value: fixPointNumber(calculateProfitApiResult?.total_fees, 2) || "N/a",
    },
    {
      label: "Estimated Profit (USD)",
      value:
        fixPointNumber(calculateProfitApiResult?.estimated_profit, 2) || "N/a",
    },
  ];

  return {
    loading,
    handleCalculateProfit,
    estimatedProfit,
    feeAndTaxes,
    form,
    calculateProfitApiResult,

    totalFees: {
      value: calculateProfitApiResult?.total_fees || "N/a",
      label: "Total Fees",
    },
    totalEstimatedMargin: {
      value: calculateProfitApiResult?.estimated_margin || "N/a",
      label: "Total Estimated Margin",
    },
  };
};

export default useDashboardCalculator;
