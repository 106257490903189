import { WithHeading } from "hoc";
import { AuthLayout, DashboardLayout, PageLayout } from "layouts";
import { Home } from "pages/app";

import {
  Calendar,
  ChangePassword,
  Comparison,
  DashboardCalculator,
  Home as DashboardHome,
  DashboardListing,
  Help,
  History,
  Payment,
  Profile,
} from "pages/dashboard";

import { ForgotPassword, Login, Register, ResetPassword } from "pages/auth";
import { StripePaymentStatus } from "pages/stripe";
import { Navigate, createBrowserRouter } from "react-router-dom";

const getRoutes = (isAuthenticated, user) => {
  const isTrialPeriodValid = user?.isTrialPeriodValid;
  const isPayed = user?.isPayed;

  return createBrowserRouter([
    {
      path: "auth",
      element: isAuthenticated ? (
        <Navigate
          to={isPayed || isTrialPeriodValid ? "/app" : "/app/payment"}
          replace
        />
      ) : (
        <AuthLayout />
      ),
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <Register />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "",
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
      ],
    },
    {
      path: "app",
      element: !isAuthenticated ? (
        <Navigate to="/auth/login" replace />
      ) : isPayed || isTrialPeriodValid ? (
        <DashboardLayout />
      ) : (
        <Navigate to={"/payment"} replace />
      ),
      children: [
        {
          index: true,
          element: (
            <WithHeading heading="Main Search" Component={<DashboardHome />} />
          ),
        },
        {
          path: "listing/:id?",
          element: (
            <WithHeading
              heading="Single Listing"
              Component={<DashboardListing />}
            />
          ),
        },
        {
          path: "calculator",
          element: (
            <WithHeading
              heading="calculator"
              Component={<DashboardCalculator />}
            />
          ),
        },
        {
          path: "calendar",
          element: <WithHeading heading="Calendar" Component={<Calendar />} />,
        },
        {
          path: "comparison",
          element: (
            <WithHeading heading="Comparison" Component={<Comparison />} />
          ),
        },
        {
          path: "history",
          element: <WithHeading heading="History" Component={<History />} />,
        },
        {
          path: "help",
          element: <WithHeading heading="Help" Component={<Help />} />,
        },
        {
          path: "profile",
          element: <WithHeading heading="My Profile" Component={<Profile />} />,
        },
        {
          path: "change-password",
          element: (
            <WithHeading
              heading="Change Password"
              Component={<ChangePassword />}
            />
          ),
        },
      ],
    },
    {
      path: "payment",
      element: isAuthenticated ? (
        isPayed ? (
          <Navigate to="/app" />
        ) : (
          <DashboardLayout disabled={!isPayed && !isTrialPeriodValid} />
        )
      ) : (
        <Navigate replace to="/auth/login" />
      ),
      children: [
        {
          index: true,
          element: <WithHeading heading="Payment" Component={<Payment />} />,
        },
      ],
    },
    {
      path: "stripe-payment-status",
      element: <StripePaymentStatus />,
    },
    {
      path: "*",
      element: <Navigate to="/app" replace />,
    },
  ]);
};

export default getRoutes;
