import { Col, Row } from "antd";
import { ComparisonColSection } from "./Component";
import "./styles.scss";
import useComparison from "./useComparison";

const Comparison = () => {
  const {
    handleSearch,
    firstComparisonSectionApiResult,
    secondComparisonSectionApiResult,
    thirdComparisonSectionApiResult,
    firstPopularTagsResult,
    secondPopularTagsResult,
    thirdPopularTagsResult,
    firstComparisonSectionApiLoading,
    secondComparisonSectionApiLoading,
    thirdComparisonSectionApiLoading,
  } = useComparison();

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24} md={8}>
          <ComparisonColSection
            onSearch={(keyword) => handleSearch(keyword, 1)}
            result={firstComparisonSectionApiResult}
            topTags={firstPopularTagsResult}
            loading={firstComparisonSectionApiLoading}
          />
        </Col>
        <Col xs={24} md={8}>
          <ComparisonColSection
            onSearch={(keyword) => handleSearch(keyword, 2)}
            result={secondComparisonSectionApiResult}
            topTags={secondPopularTagsResult}
            loading={secondComparisonSectionApiLoading}
          />
        </Col>
        <Col xs={24} md={8}>
          <ComparisonColSection
            onSearch={(keyword) => handleSearch(keyword, 3)}
            result={thirdComparisonSectionApiResult}
            topTags={thirdPopularTagsResult}
            loading={thirdComparisonSectionApiLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default Comparison;
