import { Button } from "antd";
import { useState } from "react";
const Searchbar = ({ onSearch, loading }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="custom-searchbar">
      <input
        placeholder="Search By Keyword"
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            handleSearch();
          }
        }}
      />
      <Button
        type="primary"
        size="lg"
        className="font-bold"
        onClick={handleSearch}
        loading={loading}
      >
        SEARCH
      </Button>
    </div>
  );
};

export default Searchbar;
