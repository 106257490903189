import { getCalendarHolidaysApiAxiosParams } from "api/axiosHookParams";
import { useAxios } from "hooks";
import { useMemo } from "react";
import { getStartAndEndOfDay, getRandomLightColor } from "utils";

const useCalendar = () => {
  const { result, loading } = useAxios(getCalendarHolidaysApiAxiosParams);

  const calendarHolidays = useMemo(() => {
    if (result !== null) {
      return result?.country_holidays.map((calendarHoliday) => {
        const { startOfDay, endOfDay } = getStartAndEndOfDay(
          calendarHoliday.date
        );
        return {
          title: calendarHoliday?.name,
          start: startOfDay,
          end: endOfDay,
          color: getRandomLightColor(),
        };
      });
    } else {
      return [];
    }
  }, [result]);

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color,
      color: "black",
      border: "0",
      display: "block",
      margin: "2px 0",
      fontSize: 10,
      fontWeight: 500,
      padding: "2px 4px",
      borderRadius: 2,
      fontFamily: "Lato",
    };
    return {
      style: style,
    };
  };

  return { eventStyleGetter, calendarHolidays, loading };
};

export default useCalendar;
