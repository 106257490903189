import { Table } from "antd";
import { DOUBLE_BACKWARD, DOUBLE_FORWARD } from "assets";
import ReactPaginate from "react-paginate";
import { ReactSVG } from "react-svg";
// import IconInput from "../IconInput";
import useCustomTable from "./useCustomTable";

const CustomTable = ({
  tableTitle,
  columns,
  dataSource,
  className = "",
  searchKeys,
  // searchPlaceholder,
  handleRowClick,
  rowKey = "",
  loading: initialLoading = false,
  fetchData,
  paginationMode = "manual",
  initialSearchValue = "",
}) => {
  const {
    pageCount,
    handlePageChange,
    currentData,
    // handleTableSearch,
    currentPage,
    currentDataLength,
    loading,
  } = useCustomTable({
    dataSource,
    searchKeys,
    fetchData,
    paginationMode,
    initialSearchValue,
    initialLoading,
  });

  return (
    <div className={`custom-table card-layout ${className}`}>
      <div className="card-layout-header">
        <h3 className="card-heading">{tableTitle}</h3>

        {/* {searchKeys && (
          <IconInput
            placeholder={searchPlaceholder || "Search Here"}
            onSearch={handleTableSearch}
            initialSearchValue={initialSearchValue}
          />
        )} */}
      </div>
      <div className="custom-table-wrapper">
        <Table
          loading={loading}
          pagination={false}
          columns={columns}
          dataSource={currentData}
          scroll={{ x: 800 }}
          rowKey={rowKey}
          rowClassName={handleRowClick ? "cursor-pointer" : ""}
          onRow={
            handleRowClick
              ? (record) => ({
                  onClick: () => handleRowClick(record),
                })
              : null
          }
        />
      </div>
      <div className="custom-table-footer">
        <p>
          Showing {currentData?.length} of {currentDataLength}
        </p>
        <div className="custom-pagination-wrapper">
          <ReactPaginate
            containerClassName="custom-pagination"
            previousLabel={
              currentDataLength > 2 ? (
                <IconLabel
                  icon={DOUBLE_BACKWARD}
                  label="previous"
                  type="previous"
                />
              ) : (
                <></>
              )
            }
            nextLabel={
              currentDataLength > 2 ? (
                <IconLabel icon={DOUBLE_FORWARD} label="next" type="next" />
              ) : (
                <></>
              )
            }
            pageRangeDisplayed={5}
            pageClassName="custom-pagination-a-wrapper"
            pageCount={pageCount}
            onPageChange={handlePageChange}
            forcePage={currentPage}
          />
        </div>
      </div>
    </div>
  );
};

const IconLabel = ({ label, icon, type = "next" }) => (
  <div className={`ct-icon-label ${type}`}>
    <ReactSVG src={icon} alt="" />
    <span>{label}</span>
  </div>
);

export default CustomTable;
