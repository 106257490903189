import axios from "axios";
import { LOCAL_STORAGE_VALUES } from "constants/common";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// to add token in request if present

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_VALUES.ACCESS_TOKEN);
    if (token) {
      config.headers["x-access-token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
