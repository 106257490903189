import { CustomTable } from "global/components";
import React from "react";

import useHistory from "./useHistory";

const History = () => {
  const { historyDataSource, historyColumns, loading } = useHistory();

  console.log({ historyDataSource });

  return (
    <CustomTable
      dataSource={historyDataSource}
      columns={historyColumns}
      tableTitle={"History"}
      loading={loading}
    />
  );
};

export default History;
