import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleGoToHome = () => {
    navigate("/app");
  };

  return { setCollapsed, collapsed, handleCollapse, handleGoToHome };
};

export default useSidebar;
