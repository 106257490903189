import React from 'react';
import { ReactSVG } from "react-svg";

const CardVariant1 = ({ title, number, icon }) => {


  return (
    <div className='card-variant-1'>
      <div className='cv-1-left'>
        <h3> {number}</h3>
        <p>{title}</p>
      </div>
      <div className='cv-1-right'>
      <ReactSVG src={icon} alt="" />
      </div>
    </div>
  );
};

export default CardVariant1;
