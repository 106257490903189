import { Button, Image, Modal } from "antd";
import { useMemo, useState } from "react";

const ImagesSection = ({ images = [] }) => {


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const tenImages = useMemo(() => images.slice(0, 10) , [images])

  return (
    <>
      <div className="section-margin-top">
        <div className="card-layout">
          <div className="card-layout-header">
            <h3 className="card-heading">Images</h3>
            {images.length > 10 && (
              <Button type="text" onClick={showModal}>
                See All
              </Button>
            )}
          </div>
          <div className="card-images-wrapper">
            {tenImages.map((image, index) => (
              <div className="card-image-card" key={index}>
                <Image  height={200} width={'100%'} style={{borderRadius : 10}} src={image?.url_fullxfull} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal
        title="All Images"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <div className="card-images-wrapper">
          {images.map((image, index) => (
            <div className="card-image-card" key={index}>
              <img className="image" src={image?.url_fullxfull} alt="" />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ImagesSection;
