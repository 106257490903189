const options = {
  chart: {
    type: "bar",
    height: 350,
    borderRadius: 5,
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: false,
      columnWidth: "30%", // Change the width of the bars here
    },
  },
  grid: {
    borderColor: "transparent",
  },
  dataLabels: {
    enabled: false,
  },

  yaxis: {
    tickAmount: 8,
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
  },
  fill: {
    colors: ["#3626A7"],
  },
};

const data = {
  series: [
    {
      name: "Engagement Over Time",
      data: [
        3000, 4500, 5500, 6500, 7000, 7500, 6500, 5500, 4500, 3500, 2500, 1500,
      ],
    },
  ],
};

export { data, options };
