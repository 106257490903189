import { useMemo, useState } from "react";
import { truncateText } from "utils";

const useCardVariantListing2 = ({ content, textLimit }) => {
  const [isReadMore, setIsReadMore] = useState(false);

  const onToggleRead = () => setIsReadMore(!isReadMore);

  const description = useMemo(
    () => (isReadMore ? content : truncateText(content, textLimit)),
    [content, textLimit, isReadMore]
  );

  return { isReadMore, onToggleRead, description };
};

export default useCardVariantListing2;
