import { message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "services/axios";

const useScrollToTop = (scrollRef = null) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [pathname, scrollRef]);
};

function usePageTop(ref = null) {
  const [isPageTop, setIsPageTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const isPageTop = ref ? ref.current.scrollTop < 50 : window.scrollY < 50;
      setIsPageTop(isPageTop);
    };

    const scrollTarget = ref ? ref.current : window;
    scrollTarget.addEventListener("scroll", handleScroll);
    return () => scrollTarget.removeEventListener("scroll", handleScroll);
  }, [ref]);

  return isPageTop;
}

const useAxios = (config) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const axiosProps = useRef(config);
  const isMounted = useRef(false);
  const controllerRef = useRef(new AbortController());

  const callAxios = useCallback(
    async (additionalParams) => {
      let { config, showMessage } = { ...axiosProps.current };
      try {
        setLoading(true);

        if (additionalParams) {
          config = {
            ...config,
            ...additionalParams,
          };
        }
        config = {
          ...config,
          signal: controllerRef.current.signal,
        };

        const response = await axios(config);
        setResult(response?.data || response);

        if (showMessage) {
          if (response.status === 200) {
            message.success(
              response?.data?.msg || "Operation done successfully"
            );
          } else {
            message.error("Something went wrong");
          }
        }

        return response?.data;
      } catch (error) {
        setError(error);
        showMessage &&
          message.error(
            error?.response?.data?.error_msg ||
              error?.response?.data?.msg ||
              "Something went wrong"
          );

      } finally {
        setLoading(false);
      }
    },
    [axiosProps, controllerRef]
  );

  const cancelRequest = useCallback(() => {
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
  }, []);

  useEffect(() => {
    if (!isMounted.current && process.env.NODE_ENV === "development") {
      isMounted.current = true;
      return;
    }
    const { shouldCallOnMount } = axiosProps.current;

    if (shouldCallOnMount) {
      callAxios();
    }
  }, [axiosProps, callAxios]);

  return { result, loading, error, callAxios, cancelRequest };
};

// Usage

// Hook
const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

const useLaterEffect = (callback, dependencyArray) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      callback();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyArray);
};

export { useScrollToTop, usePageTop, useAxios, useDebounce, useLaterEffect };
