import useUser from "context/User/useUser";
import { RouterProvider } from "react-router-dom";
import getRoutes from "./routes";
import { SubLoader } from "global/components";

const RouterConfig = () => {
  const { isAuthenticated, user } = useUser();

  return isAuthenticated === null ? (
    <SubLoader loading fullScreen />
  ) : (
    <RouterProvider router={getRoutes(isAuthenticated, user)} />
  );
};

export default RouterConfig;
