import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useCustomTable = ({
  dataSource,
  searchKeys,
  paginationMode,
  fetchData,
  initialSearchValue,
  initialLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(initialSearchValue || "");
  const [currentData, setCurrentData] = useState([]);
  const [currentDataLength, setCurrentDataLength] = useState(0);
  const [loading, setLoading] = useState(initialLoading || false);
  const itemsPerPage = useRef(10).current;

  // Backend data fetching
  const fetchBackendData = useCallback(
    async ({ searchKeyword, offset, itemsPerPage, fetchData }) => {
      try {
        setLoading(true);
        const { count, data } = await fetchData({
          start_index: offset,
          keyword: searchKeyword,
          limit: itemsPerPage,
        });
        setCurrentData(data);
        setCurrentDataLength(count);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
        // Handle error appropriately
      } finally {
        setLoading(false);
      }
    },
    []
  );

  // Page change handler
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
    setOffset(selected * itemsPerPage);
  };

  // Search handler
  const handleTableSearch = (keyword) => {
    setSearchKeyword(keyword);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (paginationMode === "backend") {
      fetchBackendData({
        searchKeyword,
        offset,
        itemsPerPage,
        fetchData,
      });
    } else {
      // Manual (frontend) pagination
      const filteredData = dataSource.filter((item) =>
        searchKeyword && searchKeys
          ? searchKeys.some((key) =>
              item[key]
                .toString()
                .toLowerCase()
                .includes(searchKeyword.toLowerCase())
            )
          : true
      );
      setCurrentDataLength(filteredData.length);
      setCurrentData(filteredData.slice(offset, offset + itemsPerPage));
    }
  }, [
    searchKeyword,
    offset,
    itemsPerPage,
    dataSource,
    searchKeys,
    fetchBackendData,
    paginationMode,
    fetchData,
  ]);

  // Calculate the total pages
  const pageCount = useMemo(() => {
    return Math.ceil(currentDataLength / itemsPerPage);
  }, [currentDataLength, itemsPerPage]);

  return {
    itemsPerPage,
    handlePageChange,
    currentData,
    handleTableSearch,
    currentPage,
    currentDataLength,
    pageCount,
    loading,
  };
};

export default useCustomTable;
