import { Badge, Progress, Tag } from "antd";
import themeVariables from "styles/antd/variables.json";
import { fixPointNumber } from "utils";

export const popularTagsColumns = [
  {
    title: "Tag",
    dataIndex: "keyword",
  },
  {
    title: "Count",
    dataIndex: "count",
    render: (value) => (
      <Progress
        strokeColor={themeVariables.colorPrimary}
        percent={value}
        size={30}
      />
    ),
  },
  {
    title: "Day to Ship",
    dataIndex: "days_to_ship",
  },
  {
    title: "Photos",
    dataIndex: "photos",
  },
  {
    title: "Avg Price",
    dataIndex: "price",
    render: (value) => fixPointNumber(value, 2),
  },
];

export const similarShopperSearchesColumns = [
  {
    title: "Keyword",
    dataIndex: "keyword",
  },
  {
    title: "Long Tail",
    dataIndex: "long_tail",
    render: (value) => (
      <Badge
        showZero
        style={{ width: 50 }}
        color={value ? "#52c41a" : "#faad14"}
        count={value ? "YES" : "NO"}
      />
    ),
  },
  {
    title: "Views",
    dataIndex: "views",
  },

  {
    title: "Price(Average)",
    dataIndex: "price",
    render: (value) => fixPointNumber(value, 2),
  },
  {
    title: "Photos",
    dataIndex: "photos",
  },
];

export const longTailAlternativesColumns = [
  {
    title: "Keyword",
    dataIndex: "keyword",
  },
  {
    title: "Long Tail",
    dataIndex: "long_tail",
    render: (value) => (
      <Badge
        showZero
        style={{ width: 50 }}
        color={value ? "#52c41a" : "#faad14"}
        count={value ? "YES" : "NO"}
      />
    ),
  },
  {
    title: "Views",
    dataIndex: "views",
  },

  {
    title: "Price(Average)",
    dataIndex: "price",
    render: (value) => fixPointNumber(value, 2),
  },
  {
    title: "Photos",
    dataIndex: "photos",
  },
];

export const sampleListingColumns = [
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Photo Score",
    dataIndex: "views",
    width: "10%",
  },
  {
    title: "Days to Ship",
    dataIndex: "days_to_ship",
    width: "13%",
  },
  {
    title: "Photos",
    width: "13%",
    render: (item) => item?.images?.length,
  },
  {
    title: "Tags",
    render: (item) =>
      item?.tags ? item?.tags?.map((tag) => <Tag>{tag}</Tag>) : "",
  },
  {
    title: "Price",
    dataIndex: "price",
    render: (value) => fixPointNumber(value, 2),
    width: "13%",
  },
];
