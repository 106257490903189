import { CardVariant1 } from "global/components";

import {
  DOLLAR_ICON,
  MULTI_USER_ICON,
  SEARCH_ICON_DASHBOARD_CARD,
  TROPHY_ICON,
} from "assets";
import { fixPointNumber } from "utils";
import CompSearchBar from "../CompSearchBar";
import TopTags from "../TopTags";

const ComparisonColSection = ({ onSearch, topTags, result, loading }) => {
  return (
    <div className="comparison-col-layout">
      <div className="comp-header">
        <CompSearchBar
          placeholder="Search Here"
          onSearch={onSearch}
          loading={loading}
        />
      </div>
      <div className="comp-content">
        <div className="cards">
          <CardVariant1
            icon={TROPHY_ICON}
            number={result?.searches}
            title="Searches"
          />
        </div>
        <div className="cards">
          <CardVariant1
            icon={SEARCH_ICON_DASHBOARD_CARD}
            number={result?.competition}
            title="Competition"
          />
        </div>
        <div className="cards">
          <CardVariant1
            icon={MULTI_USER_ICON}
            number={result?.favourites}
            title="Favourites"
          />
        </div>
        <div className="cards">
          <CardVariant1
            icon={DOLLAR_ICON}
            number={fixPointNumber(result?.average_price, 2)}
            title="Average Price"
          />
        </div>
        <div className="cards">
          <CardVariant1
            icon={DOLLAR_ICON}
            number={fixPointNumber(result?.avg_searches, 2)}
            title="Average Searches"
          />
        </div>
        {topTags && (
          <div className="cards">
            <TopTags topTags={topTags} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ComparisonColSection;
