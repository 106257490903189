import { Button, Space, message } from "antd";
import { checkUserExistAxiosParams } from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import { MESSAGE_ICON } from "assets";
import { CustomInput } from "global/components";
import { useAxios } from "hooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const VerifyEmail = ({ onNextStep, initialValues }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { callAxios: callCheckUserExistApi, loading: checkingIfUserExist } =
    useAxios(checkUserExistAxiosParams);

  const handleEmailSubmit = async (data) => {
    const encodedEmail = encodeURIComponent(data.email);
    const response = await callCheckUserExistApi({
      url: `${API_ENDPOINTS.CHECK_USER_EXIST}?email=${encodedEmail}`,
    });

    if (response?.status === 200) {
      onNextStep(data);
    } else {
      message.error("Email already exist");
    }
  };

  useEffect(() => {
    if (initialValues?.email) {
      setValue("email", initialValues.email);
    }
  }, [initialValues, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(handleEmailSubmit)}>
        <p>Please enter a valid email to verify</p>
        <Space direction="vertical" className="w-100">
          <CustomInput
            label="Email"
            required
            icon={MESSAGE_ICON}
            placeholder="example@gmail.com"
            error={errors["email"]}
            {...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
        </Space>
        <Button
          block
          type="primary"
          loading={checkingIfUserExist}
          className="custom-btn"
          htmlType="submit"
        >
          Continue
        </Button>
      </form>
    </>
  );
};

export default VerifyEmail;
