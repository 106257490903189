
const CardVariant2 = ({ data, title }) => {
  return (
    <div className="card-variant-2">
      <div className="cv-2-header">
        <h4>{title}</h4>
      </div>
      <div className="cv-2-content-wrapper">
        {data.map(({ label, value }) => (
          <div className="cv-2-row" key={label}>
            <p className="cv-2-row-label">{label}</p>
            <p className="cv-2-row-value">{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardVariant2;
