import { Button, Space } from "antd";
import { PASSWORD_ICON } from "assets";
import { CustomInput } from "global/components";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { passwordValidationRules } from "./rules";

const VerifyPassword = ({ onNextStep, initialValues }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (initialValues?.password) {
      setValue("password", initialValues.password);
    }
    if (initialValues?.confirmPassword) {
      setValue("confirmPassword", initialValues.confirmPassword);
    }
  }, [initialValues, setValue]);

  return (
    <form onSubmit={handleSubmit((data) => onNextStep(data))}>
      <p>Please enter password for your account</p>
      <Space direction="vertical" className="w-100">
        <CustomInput
          label="Password"
          icon={PASSWORD_ICON}
          type="password"
          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          error={errors["password"]}
          required
          {...register("password", passwordValidationRules)}
        />
        <CustomInput
          label="Confirm Password"
          icon={PASSWORD_ICON}
          type="password"
          placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
          error={errors["confirmPassword"]}
          required
          {...register("confirmPassword", {
            required: true,
            validate: (val) => {
              if (watch("password") !== val) {
                return "Your passwords do no match";
              }
            },
          })}
        />
      </Space>
      <Button block type="primary" className="custom-btn" htmlType="submit">
        Continue
      </Button>
    </form>
  );
};

export default VerifyPassword;
