import { AXIOS_METHODS } from "../constants/common";
import API_ENDPOINTS from "./endpoints";

export const registerApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const loginApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getCalendarHolidaysApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_CALENDAR_HOLIDAYS,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const getItemListingAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
  },
  shouldCallOnMount: false,
  showMessage: false,
};

export const getSingleItemListingAxiosParams = (id) => ({
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.GET_SINGLE_LISTING(id),
  },
  shouldCallOnMount: false,
  showMessage: false,
});

export const calculateProfitApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const updatePasswordApiAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getHistoryAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.GET_HISTORY,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const deleteAccountAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const forgotPasswordAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const updateProfileAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.UPDATE_PROFILE,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const meAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.ME,
  },
  shouldCallOnMount: true,
  showMessage: false,
};

export const verifyOptAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.VERIFY_CODE,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const resetPasswordAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.RESET_PASSWORD,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const addCustomerAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.ADD_STRIPE_CUSTOMER,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const createSubscriptionAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.CREATE_SUBSCRIPTION,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const cancelSubscriptionAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.CANCEL_SUBSCRIPTION,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getPopularTagsAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.GET_POPULAR_TAGS,
  },
  shouldCallOnMount: false,
  showMessage: false,
};

export const getLongTailAlternativeTagsAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.GET_LONG_TAIL_ALTERNATIVE_TAGS,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const getSimilarShoppingTagsAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.GET_SIMILAR_SHOPPING_TAGS,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const registerForNewsAxiosParams = {
  config: {
    method: AXIOS_METHODS.POST,
    url: API_ENDPOINTS.REGISTER_FOR_NEWS,
  },
  shouldCallOnMount: false,
  showMessage: true,
};

export const checkUserExistAxiosParams = {
  config: {
    method: AXIOS_METHODS.GET,
    url: API_ENDPOINTS.CHECK_USER_EXIST,
  },
  shouldCallOnMount: false,
  showMessage: false,
};
