import { message } from "antd";
import {
  verifyOptAxiosParams,
  forgotPasswordAxiosParams,
} from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import { LOCAL_STORAGE_VALUES } from "constants/common";
import { useAxios } from "hooks";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

const useForgotPassword = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [isEmailSent, setIsEmailSent] = useState(
    searchParams.get("token") ? true : false
  );
  const {
    callAxios: callForgotPasswordApi,
    loading: forgotPasswordApiLoading,
  } = useAxios(forgotPasswordAxiosParams);

  const { callAxios: callVerifyOtpApi, loading: verifyOtpApiLoading } =
    useAxios(verifyOptAxiosParams);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleContinue = async () => {
    isEmailSent === false
      ? setIsEmailSent(true)
      : handleNavigation("/auth/reset-password");
    if (!isEmailSent) {
      setIsEmailSent(true);
    }
  };

  const handleSendCode = async (data) => {
    try {
      await callForgotPasswordApi({
        url: API_ENDPOINTS.FORGOT_PASSWORD(data?.email),
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEmailFormSubmit = handleSubmit(handleSendCode);

  const handleCodeChange = (val) => {
    setCode(val);
  };

  const handleVerifyCode = async () => {
    if (code.length < 6) {
      message.error("Please enter the complete code");
      return;
    }
    const result = await callVerifyOtpApi({
      data: {
        otp: code,
        email: getValues("email") || searchParams.get("email"),
      },
      headers: {
        "x-access-token": searchParams.get("token"),
      },
    });
    if (result?.access_token) {
      localStorage.setItem(
        LOCAL_STORAGE_VALUES.PASSWORD_ACCESS_TOKEN,
        result?.access_token
      );
      navigate("/auth/reset-password");
    }
  };

  return {
    navigate,
    handleContinue,
    handleNavigation,
    isEmailSent,
    errors,
    register,
    handleEmailFormSubmit,
    forgotPasswordApiLoading,
    handleCodeChange,
    handleVerifyCode,
    verifyOtpApiLoading,
  };
};

export default useForgotPassword;
