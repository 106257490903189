// refresh.js
import axios from "axios";
import { LOCAL_STORAGE_VALUES } from "constants/common";

const refreshToken = async () => {
  const refresh = localStorage.getItem(LOCAL_STORAGE_VALUES.REFRESH_TOKEN);
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/refreshToken`,
      { headers: { "x-access-refresh-token": refresh } }
    );
    localStorage.setItem(
      LOCAL_STORAGE_VALUES.ACCESS_TOKEN,
      response.data?.accessToken
    );
    localStorage.setItem(
      LOCAL_STORAGE_VALUES.REFRESH_TOKEN,
      response.data?.refreshToken
    );
    return response.data?.accessToken;
  } catch (error) {
    throw error;
  }
};

export default refreshToken;
