import { Col, Row } from "antd";
import { CustomInputNumber } from "global/components";

const CardFormCalculator = ({ calculateProfitApiResult }) => {
  return (
    <div className="card-form-calculator">
      <div className="card-form-header">
        <p>How much customer pays</p>
      </div>
      <div className="card-form-content">
        <Row gutter={[30]} >
          <Col xs={12}>
            <CustomInputNumber label="Customer Price" name={"cust_price"} />
          </Col>

          <Col xs={12}>
            <CustomInputNumber
              label="Customer Shipping Price"
              name="cust_shipping_price"
            />
          </Col>

          <Col xs={12}>
            <CustomInputNumber label="Customer Coupon" name="cust_coupon" />
          </Col>

          {calculateProfitApiResult !== null && (
            <Col xs={12} className="cfc-info-col">
              <div className="cfc-info-card">
                <div className="cfc-info-card-row">
                  <p>Price (incl. Shipping):</p>
                  <p>{calculateProfitApiResult?.total_cost}</p>
                </div>
                <div className="cfc-info-card-row">
                  <p>After discount:</p>
                  <p>{calculateProfitApiResult?.discounted_price}</p>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>

      <div className="card-form-header">
        <p>Your Cost</p>
      </div>
      <div className="card-form-content">
        <Row gutter={[30]}>
          <Col xs={12}>
            <CustomInputNumber label="Labor Cost" name={"labor_cost"} />
          </Col>

          <Col xs={12}>
            <CustomInputNumber label="Material Cost" name="material_cost" />
          </Col>

          <Col xs={12}>
            <CustomInputNumber label="Shipping Cost" name="shipping_cost" />
          </Col>

          <Col xs={12}>
            <CustomInputNumber label="Etsy Ads" name="etsy_ads" />
          </Col>

          <Col xs={12}>
            <CustomInputNumber label="Renewing" name="renewing" />
          </Col>

          {calculateProfitApiResult !== null && (
            <Col xs={12} className="cfc-info-col">
              <div className="cfc-info-card">
                <div className="cfc-info-card-row">
                  <p>Price (incl. Shipping):</p>
                  <p>{calculateProfitApiResult?.total_cost}</p>
                </div>
                <div className="cfc-info-card-row">
                  <p>After discount:</p>
                  <p>{calculateProfitApiResult?.discounted_price}</p>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default CardFormCalculator;
