import { Col, Row } from "antd";
import { PASSWORD_ICON } from "assets";
import { CustomInput } from "global/components";
import { Button } from "antd";
import useChangePasswordContent from "./useChangePasswordContent";

const ChangePasswordContent = () => {
  const { handleChangePasswordFormSubmit, register, errors, watch, loading } =
    useChangePasswordContent();

  return (
    <div className="content">
      <div className="content-head">
        <h4>Update Password</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
        </p>
      </div>

      <form className="content-form" onSubmit={handleChangePasswordFormSubmit}>
        <Row gutter={[20, 30]}>
          {/* <Col xs={24}>
            <CustomInput
              label="Current Password"
              icon={PASSWORD_ICON}
              placeholder={"example@gmail.com"}
            />
          </Col> */}
          <Col xs={24}>
            <CustomInput
              label="Password"
              icon={PASSWORD_ICON}
              type="password"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              error={errors["password"]}
              {...register("password", {
                required: {
                  value: true,
                  message: "Password is required",
                },
              })}
            />
          </Col>
          <Col xs={24}>
            <CustomInput
              label="Confirm Password"
              icon={PASSWORD_ICON}
              type="password"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              error={errors["confirmPassword"]}
              {...register("confirmPassword", {
                required: {
                  value: true,
                  message: "Please confirm Password",
                },
                validate: (val) => {
                  if (watch("password") !== val) {
                    return "Your passwords do no match";
                  }
                },
              })}
            />
          </Col>
          <Col xs={24}>
            <div className="button-submit">
              <Button type="primary" htmlType="submit" loading={loading}>
                Change Password
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ChangePasswordContent;
