import { getHistoryAxiosParams } from "api/axiosHookParams";
import { useAxios } from "hooks";
import { useMemo } from "react";
import { fixPointNumber } from "utils";

const useHistory = () => {
  const { result: historyApiResult, loading: getHistoryLoading } = useAxios(
    getHistoryAxiosParams
  );

  const historyColumns = [
    {
      title: "Searches",
      dataIndex: "searches",
    },
    {
      title: "Competitions",
      dataIndex: "competition",
    },
    {
      title: "Average Prices",
      dataIndex: "average_price",
    },
  ];

  const historyDataSource = useMemo(
    () =>
      Array.isArray(historyApiResult?.user_histories)
        ? historyApiResult.user_histories.map((history) => ({
            ...history,
            average_price: fixPointNumber(history?.average_price, 2),
          }))
        : [],
    [historyApiResult]
  );

  return {
    historyDataSource,
    historyColumns,
    loading: getHistoryLoading,
  };
};

export default useHistory;
