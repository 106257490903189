import { getItemListingAxiosParams } from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import { useAxios } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "services/axios";
import { fixPointNumber } from "utils";

const useHome = () => {
  const {
    result,
    callAxios: callGetItemListingApi,
    loading: getItemListingApiLoading,
  } = useAxios(getItemListingAxiosParams);

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const itemListingResult = useMemo(
    () => (result?.status === 200 ? result?.result : {}),
    [result]
  );

  const handleSearch = async (value) => {
    setSearchValue(value);
    await callGetItemListingApi({
      url: API_ENDPOINTS.GET_LISTING(value),
    });
  };

  const shippingPriceColumnGraphProps = useMemo(() => {
    if (!itemListingResult?.shipping_prices) return { series: [], xaxis: {} };

    const binWidth = 5;
    const bins = itemListingResult.shipping_prices.reduce((acc, item) => {
      const yValue = item[1];
      if (yValue > 10) return acc; // Filtering outliers

      const bin = Math.floor(item[0] / binWidth) * binWidth;
      acc[bin] = acc[bin] || [];
      acc[bin].push(yValue);
      return acc;
    }, {});

    const binnedData = Object.entries(bins).map(([bin, values]) => {
      const sum = values.reduce((sum, val) => sum + val, 0);
      const averageY = parseFloat((sum / values.length).toFixed(3));
      return {
        x: bin,
        y: averageY,
      };
    });

    // Sort bins to ensure x-axis categories are in increasing order
    binnedData.sort((a, b) => parseFloat(a.x) - parseFloat(b.x));

    // Prepare series and x-axis categories
    return {
      series: [
        {
          name: "Shipping Price",
          data: binnedData.map((data) => data.y),
        },
      ],
      xaxis: {
        categories: binnedData.map(
          (data) => `${data.x}-${data.x + binWidth} USD`
        ),
      },
    };
  }, [itemListingResult]);

  const itemPriceDotGraphSeries = useMemo(() => {
    if (!itemListingResult) return;
    return [
      {
        name: "Item Price",
        data:
          itemListingResult?.item_pricing &&
          itemListingResult?.item_pricing.map((item) => ({
            y: +item[0],
            x: item[1],
          })),
      },
    ];
  }, [itemListingResult]);

  const popularTagsDataSource = useMemo(() => {
    if (itemListingResult !== null) {
      const popularTags = Array.isArray(itemListingResult?.popular_tags)
        ? itemListingResult?.popular_tags.flat()
        : [];
      return popularTags
        .map((item, index) =>
          index % 2 === 0
            ? item
            : {
                tag: popularTags[index - 1],
                price: fixPointNumber(item.price, 2),
                ...item,
              }
        )
        .filter((_, index) => index % 2 !== 0);
    } else {
      return [];
    }
  }, [itemListingResult]);

  const similarShopperSearchesDataSource = useMemo(() => {
    if (itemListingResult !== null) {
      const similarShopperSearches = Array.isArray(
        itemListingResult?.similar_shopper_searches
      )
        ? itemListingResult?.similar_shopper_searches.flat()
        : [];
      return similarShopperSearches
        .map((item, index) =>
          index % 2 === 0
            ? item
            : { keyword: similarShopperSearches[index - 1], ...item }
        )
        .filter((_, index) => index % 2 !== 0);
    } else {
      return [];
    }
  }, [itemListingResult]);

  const longTailAlternativesDataSource = useMemo(() => {
    if (itemListingResult !== null) {
      const longTailAlternatives = Array.isArray(
        itemListingResult?.long_tail_alternatives
      )
        ? itemListingResult?.long_tail_alternatives.flat()
        : [];
      return longTailAlternatives
        .map((item, index) =>
          index % 2 === 0
            ? item
            : {
                keyword: longTailAlternatives[index - 1],
                price: fixPointNumber(item.price, 2),
                ...item,
              }
        )
        .filter((_, index) => index % 2 !== 0);
    } else {
      return [];
    }
  }, [itemListingResult]);

  const handleSampleListingTableRowClick = useCallback(
    (data) => {
      data.listing_id &&
        navigate(`/app/listing?searchText=${data?.listing_id}`);
    },
    [navigate]
  );

  const images = useMemo(() => {
    if (itemListingResult !== null) {
      return itemListingResult?.items?.reduce((prev, curr) => {
        return [...prev, ...curr?.images?.map((c) => c)];
      }, []);
    } else {
      return [];
    }
  }, [itemListingResult]);

  const callGetPopularTagsApi = useCallback(async (data) => {
    try {
      const toReturn = {
        count: 0,
        data: [],
      };
      const response = await axiosInstance.post(
        API_ENDPOINTS.GET_POPULAR_TAGS,
        data
      );

      if (response?.data?.status === 200) {
        toReturn.count = response?.data?.count;
        const popularTags = response?.data?.popular_tags?.flat();
        toReturn.data = popularTags
          .map((item, index) =>
            index % 2 === 0
              ? item
              : {
                  keyword: popularTags[index - 1],
                  ...item,
                }
          )
          .filter((_, index) => index % 2 !== 0);
      }
      return toReturn;
    } catch (e) {}
  }, []);

  const callGetLongTailAlternativeTagsApi = useCallback(async (data) => {
    try {
      const toReturn = {
        count: 0,
        data: [],
      };
      const response = await axiosInstance.post(
        API_ENDPOINTS.GET_LONG_TAIL_ALTERNATIVE_TAGS,
        data
      );
      if (response?.data?.status === 200) {
        toReturn.count = response?.data?.count;
        const longTailAlternatives =
          response?.data?.long_tail_alternatives?.flat();
        toReturn.data = longTailAlternatives
          .map((item, index) =>
            index % 2 === 0
              ? item
              : {
                  keyword: longTailAlternatives[index - 1],
                  ...item,
                }
          )
          .filter((_, index) => index % 2 !== 0);
      }

      return toReturn;
    } catch (e) {}
  }, []);

  const callSimilarShoppingTagsApi = useCallback(async (data) => {
    try {
      const toReturn = {
        count: 0,
        data: [],
      };
      const response = await axiosInstance.post(
        API_ENDPOINTS.GET_SIMILAR_SHOPPING_TAGS,
        data
      );
      if (response?.data?.status === 200) {
        toReturn.count = response?.data?.count;
        const similarShopperSearches =
          response?.data?.similar_shopper_searches?.flat();
        toReturn.data = similarShopperSearches
          .map((item, index) =>
            index % 2 === 0
              ? item
              : {
                  keyword: similarShopperSearches[index - 1],
                  ...item,
                }
          )
          .filter((_, index) => index % 2 !== 0);
      }
      return toReturn;
    } catch (e) {
      console.error(e);
    }
  }, []);

  return {
    popularTagsDataSource,
    sampleListingDataSource: itemListingResult?.items || [],
    similarShopperSearchesDataSource,
    longTailAlternativesDataSource,
    shippingPriceColumnGraphProps,
    handleSearch,
    itemListingResult,
    getItemListingApiLoading,
    itemPriceDotGraphSeries,
    handleSampleListingTableRowClick,
    images,
    callGetPopularTagsApi,
    callGetLongTailAlternativeTagsApi,
    callSimilarShoppingTagsApi,
    searchValue,
    pricingGraph: result?.result?.pricing_graph || null,
  };
};

export default useHome;
