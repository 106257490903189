import { Col, Row } from "antd";
import "./styles.scss";

import { ChangePasswordContent } from "./components";

const ChangePassword = () => {
  return (
    <div className="change-password  w-100">
      <Row gutter={[30, 30]}>
        <Col xs={24} md={12}>
          <ChangePasswordContent />
        </Col>
        {/* <Col xs={24} md={12}>
          <WarningBox />
        </Col> */}
      </Row>
    </div>
  );
};

export default ChangePassword;
