import { Col, Row } from "antd";
import CardVariantListing from "../CardVariantListing";

import CardVariantListing2 from "../CardVariantListing2";

const AboutSection = ({ about }) => {
  const data = [
    {
      label: "Title Characters",
      value: about?.title_characters,
    },
    {
      label: "Title Words",
      value: about?.words_in_title,
    },
    {
      label: "Description Characters",
      value: about?.descripiton_characters,
    },
    {
      label: "Images",
      value: about?.images?.length,
    },
  ];

  return (
    <div className="about-us-dashboard section-margin-top">
      <Row gutter={[30, 20]}>
        <Col xs={24} md={16}>
          <CardVariantListing2
            icon={about?.user_profile_image}
            heading={about?.title}
            content={about?.description}
            textLimit={500}
          />
        </Col>
        <Col xs={24} md={8}>
          <CardVariantListing
            data={data}
            title={"Category"}
            subTitle={about?.category}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AboutSection;
