import { Form, Radio } from "antd";

const CardShowCalculation = ({ data, title, hasRadio, total }) => {
  return (
    <div className="card-calculation">
      <div className="card-header">
        <h4>{title}</h4>
      </div>
      <div className="card-content">
        {hasRadio && (
          <div className="ccc-row">
            <div className="cc-label">
              <p className="m-0">{"Offsite Ads Fee"}</p>
            </div>

            <Form.Item name="offside_ads_fee_per" className="m-0">
              <Radio.Group>
                <Radio value={0}>0%</Radio>
                <Radio value={12}>12%</Radio>
                <Radio value={15}>15%</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        {data?.map(({ label, value }) => (
          <div className="ccc-row" key={label}>
            <div className="cc-label">
              <p className="m-0">{label}</p>
            </div>
            <div className="cc-value">
              <p className="m-0">{value}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="card-bottom">
        <div className="cb-content">
          <p>{total?.label}: </p>
          <h4> {total?.value} </h4>
        </div>
      </div>
    </div>
  );
};

export default CardShowCalculation;
