import { useRef, useState } from "react";
import { deleteAccountAxiosParams } from "api/axiosHookParams";
import useUser from "context/User/useUser";
import { useAxios } from "hooks";
import API_ENDPOINTS from "api/endpoints";
import { updateProfileAxiosParams } from "api/axiosHookParams";
import { cancelSubscriptionAxiosParams } from "api/axiosHookParams";

const useProfile = () => {
  const { callAxios: callDeleteAccountApi, loading: deleteAccountApiLoading } =
    useAxios(deleteAccountAxiosParams);

  const { callAxios: callUpdateProfileApi, loading: updateProfileApiLoading } =
    useAxios(updateProfileAxiosParams);

  const {
    callAxios: callCancelSubscriptionApi,
    loading: cancelSubscriptionApiLoading,
  } = useAxios(cancelSubscriptionAxiosParams);

  const { onLogoutUser, user, setUser } = useUser();

  const uploadInputRef = useRef();
  const fileSelectRef = useRef();

  const [selectedImageUrl, setSelectedImageUrl] = useState(
    user?.image_url || ""
  );

  const handleAccountDelete = async () => {
    const email = user?.email;
    if (!email) {
      return;
    }
    await callDeleteAccountApi({
      url: API_ENDPOINTS.DELETE_ACCOUNT(email),
    });
    onLogoutUser();
  };

  const handleCancelSubscription = async () => {
    const result = await callCancelSubscriptionApi();
    if (result.status === 200) {
      window.location.reload();
    }
  };

  const handleFileSelect = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setSelectedImageUrl(URL.createObjectURL(files[0]));
      fileSelectRef.current = files[0];
    }
  };

  const handleUpdateProfile = async (data) => {
    const formData = new FormData();
    fileSelectRef.current && formData.append("image", fileSelectRef.current);
    Object.keys(data).forEach(
      (key) => data[key] && formData.append(key, data[key])
    );
    const result = await callUpdateProfileApi({
      data: formData,
    });

    if (result.status === 200) {
      setUser((curr) => ({
        ...curr,
        email: data.email,
        contact_no: data.phone,
        country: data.country,
        date_of_birth: data.date_of_birth,
        name: data.name,
        image_url: selectedImageUrl,
      }));
    }
  };

  return {
    uploadInputRef,
    handleFileSelect,
    handleAccountDelete,
    deleteAccountApiLoading,
    handleUpdateProfile,
    updateProfileApiLoading,
    selectedImageUrl,
    handleCancelSubscription,
    cancelSubscriptionApiLoading,
    user,
  };
};

export default useProfile;
