import { Col, Row } from "antd";
import { CardVariant2 } from "global/components";
import { fixPointNumber } from "utils";
import GroupedColumnGraph from "../GroupedColumnGraph";

const PricingSection = ({
  itemListingResult,
  pricingGraph,
}) => {

  console.log(itemListingResult?.shipping_day_prices?.average_price)

  const shippingData = [
    {
      label: "Minimun Shipping (USD)",
      value: itemListingResult?.shipping_day_prices?.minimum_price,
    },
    {
      label: "Average Shipping (USD)",
      value: fixPointNumber(
        itemListingResult?.shipping_day_prices?.average_price,
        2
      ),
    },
    {
      label: "Maximum Shipping (USD)",
      value: itemListingResult?.shipping_day_prices?.maximum_price,
    },
  ];

  const shippingDaysData = [
    {
      label: "Minimun Days",
      value: itemListingResult?.shipping_day_prices?.minimum_days,
    },
    {
      label: "Average Days",
      value: fixPointNumber(
        itemListingResult?.shipping_day_prices?.average_days,
        2
      ),
    },
    {
      label: "Maximum Days",
      value: itemListingResult?.shipping_day_prices?.maximum_days,
    },
  ];

  return (
    <Row gutter={[30, 20]} className="section-margin-top">
      {pricingGraph && (
        <Col xs={24} md={16}>
          <GroupedColumnGraph data={pricingGraph} />
        </Col>
      )}
      <Col xs={24} md={8}>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={24}>
            <CardVariant2 data={shippingData} title="Shipping Price" />
          </Col>
          <Col xs={24} md={24}>
            <CardVariant2 data={shippingDaysData} title="Shipping Days" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PricingSection;
