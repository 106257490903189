import { forwardRef } from "react";

const CustomInput = forwardRef(
  ({ label = "", icon = null, error, required, ...rest }, ref) => {
    return (
      <>
        <div className={`custom-input-wrapper ${error ? "error" : ""}`}>
          <img src={icon} alt="" className="icon" />
          <div className={`custom-input-inner `}>
            <label className={required ? "required" : ""}>{label}</label>
            <input {...rest} ref={ref} />
          </div>
        </div>
        {error && <span className="form-error-message">{error?.message}</span>}
      </>
    );
  }
);

export default CustomInput;
