import {
  SEARCH_ICON,
  LISTING_ICON,
  CALCULATOR_ICON,
  CALENDER_ICON,
  COMPARISON_ICON,
  HISTORY_ICON,
  HELP_ICON,
} from "assets";

export const mainMenuItems = [
  {
    to: "/app",
    title: "Main Search",
    icon: SEARCH_ICON,
  },
  {
    to: "/app/listing",
    title: "Single Listing",
    icon: LISTING_ICON,
  },
  {
    to: "/app/calculator",
    title: "Calculator",
    icon: CALCULATOR_ICON,
  },
  {
    to: "/app/calendar",
    title: "Calender",
    icon: CALENDER_ICON,
  },
  {
    to: "/app/comparison",
    title: "Comparison",
    icon: COMPARISON_ICON,
  },
  {
    to: "/app/history",
    title: "History",
    icon: HISTORY_ICON,
  },
  {
    to: "/app/help",
    title: "Help",
    icon: HELP_ICON,
  },
];

export const OtherMenuItems = [
  {
    to: "#",
    title: "",
    icon: "",
  },
  {
    to: "#",
    title: "",
    icon: "",
  },
];
