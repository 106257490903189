import { Form, InputNumber } from "antd";
import React from "react";
import { ReactSVG } from "react-svg";
import { ARROW_UP, ARROW_DOWN } from "assets";

const CustomInputNumber = (props) => {
  return (
    <Form.Item {...props}>
      <InputNumber
        className="custom-input-number w-100"
        controls={{
          upIcon: <ReactSVG src={ARROW_UP} />,
          downIcon: <ReactSVG src={ARROW_DOWN} />,
        }}
        min={0}
      />
    </Form.Item>
  );
};

export default CustomInputNumber;
