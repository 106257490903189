import { SearchOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { CustomTable, Searchbar } from "global/components";
import { ImagesSection } from "../Home/components";
import { AboutSection, StatisticsListingSection } from "./components";
import "./styles.scss";
import useDashboardListing from "./useDashboardListing";

const DashboardListing = () => {
  const {
    tagsColumns,
    tagsDataSource,
    images,
    itemData,
    singleListingApiLoading,
    onSearch,
    searchText,
  } = useDashboardListing();

  return (
    <>

      <div className="dashboard-home-wrapper w-100">
        <Searchbar onSearch={onSearch} loading={singleListingApiLoading} />
        {searchText && !singleListingApiLoading ? (
          <>
            <AboutSection about={itemData} />
            <StatisticsListingSection statistics={itemData} />

            <CustomTable
              className="section-margin-top"
              dataSource={tagsDataSource}
              columns={tagsColumns}
              tableTitle={"Tags"}
              rowKey={"name"}
              searchKeys={["name"]}
            />
            <ImagesSection images={images} />
          </>
        ) : (
          <Result
            icon={<SearchOutlined />}
            title="Write your query in the search bar!"
          />
        )}
      </div>
    </>
  );
};

export default DashboardListing;
