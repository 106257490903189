import { Button, Col, Row } from "antd";
import { HERO_RIGHT_IMAGE } from "assets";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = ({ onInView }) => {
  const containerRef = useRef();
  const isInView = useInView(containerRef);
  const navigate = useNavigate();

  useEffect(() => {
    onInView(isInView, "#");
  }, [isInView, onInView]);

  return (
    <section className="hero-section container" id="#" ref={containerRef}>
      <Row className="flex-1">
        <Col
          xs={{
            order: 2,
            span: 24,
          }}
          md={{
            order: 1,
            span: 14,
          }}
          className="hero-left"
        >
          <div className="hero-left-inner">
            <h1 className="hero-heading primary-text-color text-huge">
              Unlocking Etsy SEO Growth with Your Comprehensive All-in-One
              Solution
            </h1>
            <p className="hero-paragraph line-height-30 text-18">
              Eprimedata empowers you with the essential insights and analytics
              required to supercharge your Etsy sales. Elevate Your Business
              with Authentic Search Data
            </p>
            <Button
              type="primary"
              className="custom-btn btn-shadow"
              onClick={() => navigate("/auth/register")}
            >
              Sign up Now
            </Button>
          </div>
        </Col>
        <Col
          xs={{
            order: 1,
            span: 24,
          }}
          md={{
            order: 1,
            span: 10,
          }}
          className="hero-right"
        >
          <img src={HERO_RIGHT_IMAGE} alt="" />
        </Col>
      </Row>
    </section>
  );
};

export default HeroSection;
