import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import useMessage from "antd/es/message/useMessage";
import { createSubscriptionAxiosParams } from "api/axiosHookParams";
import useUser from "context/User/useUser";
import { STRIPE_PAYMENT_STATUS } from "constants/common";

import { useAxios } from "hooks";
import { useState } from "react";

const useCheckoutForm = () => {
  const [stripeBasicPlanKey, stripeProPlanKey] = [
    process.env.REACT_APP_STRIPE_PLAN_BASIC_KEY,
    process.env.REACT_APP_STRIPE_PLAN_PRO_KEY,
  ];

  const elements = useElements();
  const stripe = useStripe();
  const { user, setUser } = useUser();
  const [messageApi, messageContext] = useMessage();
  const [selectedPriceId, setSelectedPriceId] = useState(stripeProPlanKey);
  const [loading, setLoading] = useState(false);

  const {
    callAxios: createStripeSubscriptionApi,
    loading: createStripeSubscriptionApiLoading,
  } = useAxios(createSubscriptionAxiosParams);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      messageApi.error("Payment service is not available at this moment.");
      return;
    }

    let subscriptionResponse;
    try {
      subscriptionResponse = await createStripeSubscriptionApi({
        data: {
          customerId: user.customer_id,
          priceId: selectedPriceId,
        },
      });
    } catch (error) {
      messageApi.error("Error creating subscription.");
      console.error(error);
      setLoading(false);
      return;
    }

    if (!subscriptionResponse) {
      return;
    }

    const clientSecret = subscriptionResponse?.clientSecret;
    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      // const domain = window.location.origin; // Gets the base URL of your app
      // const successRoute = "/stripe-payment-success"; // Define your success route
      // const returnUrl = `${domain}${successRoute}`; // Construct the full return URL

      setLoading(true);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
        },
      });

      if (result.error) {
        messageApi.error(result.error.message || "Payment failed.");
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === "succeeded"
      ) {
        setUser((curr) => ({
          ...curr,
          isPayed: true,
          subscriptionStatus: STRIPE_PAYMENT_STATUS.ACTIVE,
          isTrialPeriodValid: false,
        }));
        messageApi.success("Payment successful!");
      }
    } catch (error) {
      messageApi.error("Payment processing error.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    stripe,
    messageContext,
    stripeBasicPlanKey,
    stripeProPlanKey,
    setSelectedPriceId,
    selectedPriceId,
    loading: loading || createStripeSubscriptionApiLoading,
  };
};

export default useCheckoutForm;

// if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
//   console.log("Card details not entered correctly");
//   return;
// }

// const { error, paymentMethod } = await stripe.createPaymentMethod({
//   type: "card",
//   card: cardNumberElement,
//   billing_details: {
//     // Include any billing details here
//   },
// });
