import useLayout from "context/Layout/useLayout";
import { useEffect } from "react";

const WithHeading = ({ Component, heading }) => {
  const { setActiveHeading } = useLayout();

  useEffect(() => {
    setActiveHeading(heading);
    return () => setActiveHeading("");
  }, [heading, setActiveHeading]);

  return Component;
};

export default WithHeading;
