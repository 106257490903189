import moment from "moment";

function getRandomLightColor() {
  // Generate random values for red, green, and blue components
  let r = Math.floor(Math.random() * 156) + 100; // Range: 100-255
  let g = Math.floor(Math.random() * 156) + 100; // Range: 100-255
  let b = Math.floor(Math.random() * 156) + 100; // Range: 100-255

  // Convert RGB values to hexadecimal format
  let hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  // Return hexadecimal color code
  return hex;
}

const fixPointNumber = (number, fix) =>
  typeof(number) === 'number' ? Number(number.toFixed(fix)) : "";

const getStartAndEndOfDay = (dateString = "") => {
  let dateObj = new Date(dateString);

  // Get start of day
  let startOfDay = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate()
  );

  // Get end of day
  let endOfDay = new Date(
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate() + 1
  );
  endOfDay.setMilliseconds(endOfDay.getMilliseconds() - 1);

  return { startOfDay, endOfDay };
};

const truncateText = (text, limit) => {
  if (!text) return;
  if (text.length <= limit) {
    return text;
  }
  const truncated = text.slice(0, limit - 1) + "…";
  return truncated;
};

/**
 * Calculates if the trial period is still valid and the remaining days.
 * @param {string} creationDate - The creation date in ISO format.
 * @param {number} trialPeriodDays - The length of the trial period in days.
 * @returns {Object} - An object containing isTrialPeriodValid and trialPeriodRemainingDays.
 */
const calculateTrialPeriod = (creationDate, trialPeriodDays) => {
  // Check if creationDate is realistic
  if (
    !moment(creationDate).isValid() ||
    moment(creationDate).year() < 1000 ||
    moment(creationDate).year() > 3000
  ) {
    console.log("Invalid or unrealistic creation date");
    return {
      isTrialPeriodValid: false,
      trialPeriodRemainingDays: 0,
    };
  }

  const creationMoment = moment(creationDate);
  const currentMoment = moment();
  const differenceInDays = currentMoment.diff(creationMoment, "days");
  const isTrialPeriodValid = differenceInDays < trialPeriodDays;
  const trialPeriodRemainingDays = isTrialPeriodValid
    ? Math.ceil(trialPeriodDays - differenceInDays)
    : 0;

  return {
    isTrialPeriodValid,
    trialPeriodRemainingDays,
  };
};
export {
  getRandomLightColor,
  getStartAndEndOfDay,
  fixPointNumber,
  truncateText,
  calculateTrialPeriod,
};
