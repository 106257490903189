import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { options } from "./config";

const ColumnGraph = ({ title, series, xaxis }) => {
  const [shouldGraphRender, setShouldGraphRender] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldGraphRender(true);
    }, 300);
  }, []);

  return (
    <div className="card-layout">
      <div className="card-layout-header">
        <h3 className="card-heading">{title}</h3>
      </div>
      {shouldGraphRender && series?.length && xaxis?.categories && (
        <ReactApexChart
          options={{ ...options, xaxis }}
          series={series}
          type="bar"
          height={350}
        />
      )}
    </div>
  );
};

export default ColumnGraph;
