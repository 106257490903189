import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

const useStripePaymentStatus = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    const stripe = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      stripe.then((stripeInstance) => {
        stripeInstance
          .retrievePaymentIntent(clientSecret)
          .then(({ paymentIntent }) => {
            setPaymentStatus(paymentIntent.status);
          });
      });
    }
  }, []);

  return { paymentStatus };
};

export default useStripePaymentStatus;
