import { Badge } from "antd";
import { getSingleItemListingAxiosParams } from "api/axiosHookParams";
import { useAxios } from "hooks";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchText = useMemo(
    () => searchParams.get("searchText"),
    [searchParams]
  );

  const {
    result: singleListingApiResult,
    loading: singleListingApiLoading,
    callAxios: callSingleListingApi,
  } = useAxios(getSingleItemListingAxiosParams());

  const tagsColumns = [
    {
      title: "Tags",
      dataIndex: "name",
    },
    {
      title: "Search Volume",
      dataIndex: "searches",
    },
    {
      title: "Favorites",
      dataIndex: "favourites",
    },
    {
      title: "Average Price",
      dataIndex: "average_price",
    },
    {
      title: "Long Tail",
      dataIndex: "long_tail",
      render: (value) => (
        <Badge
          showZero
          style={{ width: 50 }}
          color={value ? "#52c41a" : "#faad14"}
          count={value ? "YES" : "NO"}
        />
      ),
    },
    {
      title: "Is in Title",
      dataIndex: "is_in_title",
      render: (value) => (
        <Badge
          showZero
          style={{ width: 50 }}
          color={value ? "#52c41a" : "#faad14"}
          count={value ? "YES" : "NO"}
        />
      ),
    },
    {
      title: "Is in Description",
      dataIndex: "is_in_description",
      render: (value) => (
        <Badge
          showZero
          style={{ width: 50 }}
          color={value ? "#52c41a" : "#faad14"}
          count={value ? "YES" : "NO"}
        />
      ),
    },
  ];

  const onSearch = (searchText) => {
    setSearchParams({
      searchText,
    });
  };

  useEffect(() => {
    const init = async () => {
      await callSingleListingApi({
        data: {
          listing_id: searchText,
        },
      });
    };
    searchText && init();
  }, [searchText, callSingleListingApi]);

  return {
    tagsColumns,
    tagsDataSource: singleListingApiResult?.item_data?.tags_data || [],
    singleListingApiResult,
    singleListingApiLoading,
    images: singleListingApiResult?.item_data?.images,
    itemData: singleListingApiResult?.item_data,
    onSearch,
    searchText,
  };
};

export default useListing;
