import { Col, Row } from "antd";

// svg
import {
  DOLLAR_ICON,
  HEART_ICON,
  MULTI_USER_ICON,
  SEARCH_ICON_DASHBOARD_CARD,
  TROPHY_ICON,
} from "assets";
import { CardVariant1 } from "global/components";
import { fixPointNumber } from "utils";
import ColumnGraph from "../ColumnGraph";

const Statistics = ({ itemListingResult }) => {
  return (
    <Row gutter={[30, 20]} className="section-margin-top">
      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={SEARCH_ICON_DASHBOARD_CARD}
          number={itemListingResult?.searches}
          title="Searches"
        />
      </Col>
      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={TROPHY_ICON}
          title="Competition"
          number={itemListingResult?.competition}
        />
      </Col>
      <Col xs={24} sm={12} md={8}>
        <CardVariant1
          icon={SEARCH_ICON_DASHBOARD_CARD}
          number={fixPointNumber(itemListingResult?.avg_searches, 2)}
          title="Average Searches"
        />
      </Col>

      <Col xs={24} md={8}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12} md={24}>
            <CardVariant1
              icon={MULTI_USER_ICON}
              number={itemListingResult?.engagement || "N/a"}
              title="Engagement"
            />
          </Col>
          <Col xs={24} sm={12} md={24}>
            <CardVariant1
              icon={DOLLAR_ICON}
              number={fixPointNumber(itemListingResult?.average_price, 2)}
              title="Average Price"
            />
          </Col>
          <Col xs={24} sm={12} md={24}>
            <CardVariant1
              icon={HEART_ICON}
              number={itemListingResult?.favourites}
              title="Favorites"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={16}>
        {itemListingResult?.historical_metrices?.trends.length > 0 && (
          <ColumnGraph
            title="Engagement Over Time"
            xaxis={{
              categories: itemListingResult?.historical_metrices?.trends?.map(
                (item) => `${item?.month}-${item?.year}`
              ),
            }}
            series={[
              {
                name: "Engagement Over Time",
                data: itemListingResult?.historical_metrices?.trends?.map(
                  (item) => item?.value
                ),
              },
            ]}
          />
        )}
      </Col>
    </Row>
  );
};

export default Statistics;
