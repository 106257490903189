import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import useCardVariantListing2 from "./useCardVariantListing2";

const CardVariantListing2 = ({ icon, heading, content, textLimit }) => {
  const { description, isReadMore, onToggleRead } = useCardVariantListing2({
    content,
    textLimit,
  });
  return (
    <div className="card-variant-listing-2">
      <div className="card-header">
        <div>
          <Avatar size={64} icon={icon ? icon : <UserOutlined />} />
        </div>
        <div className="heading">
          <p>{heading}</p>
        </div>
      </div>
      <div className="card-content">
        <p>
          {description?.split("\n").map((line, index) => {
            return (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            );
          })}
          <mark onClick={onToggleRead}>
            Read {isReadMore ? "less" : "more"}
          </mark>
        </p>
      </div>
    </div>
  );
};

export default CardVariantListing2;
