import { Button, Steps } from "antd";
import "./styles.scss";
import useRegister from "./useRegister";
import { LeftOutlined } from "@ant-design/icons";

const Register = () => {
  const { handleNavigation, activeStep, stepComponents, goBack } =
    useRegister();

  return (
    <div className="register-form-wrapper">
      <Steps
        current={activeStep}
        items={stepComponents}
        className="auth-steps"
        responsive={false}
      />

      {stepComponents[activeStep].content}

      {activeStep > 0 && (
        <div className="d-flex justify-content-center">
          <Button
            icon={<LeftOutlined />}
            type="text"
            size="small"
            onClick={goBack}
          >
            Back
          </Button>
        </div>
      )}

      <p className="text-center register-text">
        Already have an account ?{" "}
        <mark
          className="primary-text-lighten-10 font-bold cursor-pointer"
          onClick={handleNavigation("/auth/login")}
        >
          Login
        </mark>
      </p>
    </div>
  );
};

export default Register;
