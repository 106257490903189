import { updatePasswordApiAxiosParams } from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import useUser from "context/User/useUser";
import { useAxios } from "hooks";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

const useChangePasswordContent = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { callAxios: callUpdatePasswordApi, loading } = useAxios(
    updatePasswordApiAxiosParams
  );

  const { user } = useUser();

  const handleChangePassword = useCallback(
    async (values) => {
      await callUpdatePasswordApi({
        url: API_ENDPOINTS.CHANGE_PASSWORD(),
        data: {
          user: user?.email,
          password: values?.password,
        },
      });
      reset();
    },
    [callUpdatePasswordApi, reset, user]
  );
  const handleChangePasswordFormSubmit = handleSubmit(handleChangePassword);

  return { handleChangePasswordFormSubmit, register, errors, watch, loading };
};

export default useChangePasswordContent;
