import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useCalendar from "./useCalendar";
import "./styles.scss";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { SubLoader } from "global/components";

const localizer = momentLocalizer(moment);

const CustomCalendar = () => {
  const { eventStyleGetter, calendarHolidays, loading } = useCalendar();

  return (
    <>
      <SubLoader loading={loading} overlay />
      <div className="custom-calendar">
        <Calendar
          showAllEvents
          localizer={localizer}
          events={calendarHolidays}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week"]}
          eventPropGetter={eventStyleGetter}
          components={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
};

const CustomToolbar = (props) => {
  let {
    localizer: { messages },
    label,
    views,
    onNavigate,
    onView,
  } = props;

  const goToToday = () => {
    onNavigate("TODAY");
  };

  const goToPrev = () => {
    onNavigate("PREV");
  };

  const goToNext = () => {
    onNavigate("NEXT");
  };
  const goToView = (view) => {
    onView(view);
  };

  const viewNamesGroup = () => {
    if (views.length > 1) {
      return views.map((name) => (
        <Button key={name} type="primary" onClick={() => goToView(name)}>
          {messages[name]}
        </Button>
      ));
    }
  };

  return (
    <div className="custom-toolbar">
      <div className="ct-left">
        <div>
          <Button type="primary" onClick={goToPrev} icon={<LeftOutlined />} />
          <Button type="primary" onClick={goToNext} icon={<RightOutlined />} />
        </div>
        <Button type="primary" onClick={goToToday}>
          Today
        </Button>
      </div>
      <div>
        <span className="ct-current-date">{label}</span>
      </div>
      <div className="ct-right">{viewNamesGroup()}</div>
    </div>
  );
};

export default CustomCalendar;
