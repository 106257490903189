import { getPopularTagsAxiosParams } from "api/axiosHookParams";
import { getItemListingAxiosParams } from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import { useAxios } from "hooks";
import { useCallback } from "react";

const useComparison = () => {
  const {
    result: firstComparisonSectionApiResult,
    callAxios: callFirstComparisonSectionApi,
    loading: firstComparisonSectionApiLoading,
    cancelRequest: cancelFirstComparisonSectionApi,
  } = useAxios(getItemListingAxiosParams);
  const {
    result: secondComparisonSectionApiResult,
    callAxios: callSecondComparisonSectionApi,
    loading: secondComparisonSectionApiLoading,
    cancelRequest: cancelSecondComparisonSectionApi,
  } = useAxios(getItemListingAxiosParams);
  const {
    result: thirdComparisonSectionApiResult,
    callAxios: callThirdComparisonSectionApi,
    loading: thirdComparisonSectionApiLoading,
    cancelRequest: cancelThirdComparisonSectionApi,
  } = useAxios(getItemListingAxiosParams);

  const {
    result: firstPopularTagsResult,
    callAxios: callFirstPopularTagsApi,
    // loading: firstPopularTagsLoading,
    cancelRequest: cancelFirstPopularTagsRequest,
  } = useAxios(getPopularTagsAxiosParams);

  const {
    result: secondPopularTagsResult,
    callAxios: callSecondPopularTagsApi,
    // // loading: secondPopularTagsLoading,
    cancelRequest: cancelSecondPopularTagsRequest,
  } = useAxios(getPopularTagsAxiosParams);

  const {
    result: thirdPopularTagsResult,
    callAxios: callThirdPopularTagsApi,
    // // loading: thirdPopularTagsLoading,
    cancelRequest: cancelThirdPopularTagsRequest,
  } = useAxios(getPopularTagsAxiosParams);

  const handleSearch = useCallback(
    (keyword, index) => {
      const apiToCall = {
        1: callFirstComparisonSectionApi,
        2: callSecondComparisonSectionApi,
        3: callThirdComparisonSectionApi,
      };

      const apiToCancel = {
        1: cancelFirstComparisonSectionApi,
        2: cancelSecondComparisonSectionApi,
        3: cancelThirdComparisonSectionApi,
      };

      const tagsApiToCall = {
        1: callFirstPopularTagsApi,
        2: callSecondPopularTagsApi,
        3: callThirdPopularTagsApi,
      };
      const tagsCancel = {
        1: cancelFirstPopularTagsRequest,
        2: cancelSecondPopularTagsRequest,
        3: cancelThirdPopularTagsRequest,
      };

      tagsCancel[index]();

      apiToCancel[index]();

      setTimeout(async () => {
        await tagsApiToCall[index]({
          data: {
            start_index: 0,
            keyword: keyword,
            limit: 20,
          },
        });
      }, 100);

      setTimeout(async () => {
        await apiToCall[index]({
          url: API_ENDPOINTS.GET_LISTING(keyword),
        });
      }, 100);
    },
    [
      callFirstComparisonSectionApi,
      callSecondComparisonSectionApi,
      callThirdComparisonSectionApi,
      callFirstPopularTagsApi,
      callSecondPopularTagsApi,
      callThirdPopularTagsApi,
      cancelFirstPopularTagsRequest,
      cancelSecondPopularTagsRequest,
      cancelThirdPopularTagsRequest,
      firstComparisonSectionApiLoading,
      secondComparisonSectionApiLoading,
      thirdComparisonSectionApiLoading,
      cancelFirstComparisonSectionApi,
      cancelSecondComparisonSectionApi,
      cancelThirdComparisonSectionApi,
    ]
  );

  return {
    handleSearch,
    firstComparisonSectionApiResult: firstComparisonSectionApiResult?.result,
    secondComparisonSectionApiResult: secondComparisonSectionApiResult?.result,
    thirdComparisonSectionApiResult: thirdComparisonSectionApiResult?.result,
    firstPopularTagsResult: firstPopularTagsResult?.popular_tags,
    secondPopularTagsResult: secondPopularTagsResult?.popular_tags,
    thirdPopularTagsResult: thirdPopularTagsResult?.popular_tags,
    firstComparisonSectionApiLoading,
    secondComparisonSectionApiLoading,
    thirdComparisonSectionApiLoading,
  };
};

export default useComparison;
