import { resetPasswordAxiosParams } from "api/axiosHookParams";
import { LOCAL_STORAGE_VALUES } from "constants/common";
import { useAxios } from "hooks";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const useResetPassword = () => {
  const navigate = useNavigate();
  const { callAxios: callResetPasswordApi, loading: resetPasswordApiLoading } =
    useAxios(resetPasswordAxiosParams);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleUpdatePassword = handleSubmit(async (values) => {
    const token = localStorage.getItem(
      LOCAL_STORAGE_VALUES.PASSWORD_ACCESS_TOKEN
    );
    const result = await callResetPasswordApi({
      data: {
        token,
      },
      params: {
        password: values.password,
      },
    });
    if (result?.status === 200) {
      localStorage.removeItem(LOCAL_STORAGE_VALUES.PASSWORD_ACCESS_TOKEN);
      navigate("/auth/login");
    }
  });

  return {
    navigate,
    handleNavigation,
    register,
    handleUpdatePassword,
    errors,
    getValues,
    resetPasswordApiLoading,
  };
};

export default useResetPassword;
