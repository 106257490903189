import { Button } from "antd";
import { STRIPE_PAYMENT_STATUS } from "constants/common";
import useUser from "context/User/useUser";
import { useNavigate, useLocation } from "react-router-dom";

const SubscriptionInfo = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const getSubscriptionMessage = () => {
    // Check if in trial period
    if (user?.isTrialPeriodValid) {
      return `Trial: ${user.trialPeriodRemainingDays} days remaining`;
    }

    // Simplified messages for subscription status
    switch (user?.subscriptionStatus) {
      case STRIPE_PAYMENT_STATUS.PAST_DUE:
      case STRIPE_PAYMENT_STATUS.NO_SUBSCRIPTION:
        return "Payment Due";
      case STRIPE_PAYMENT_STATUS.INCOMPLETE:
      case STRIPE_PAYMENT_STATUS.INCOMPLETE_EXPIRED:
        return "Setup Incomplete";
      case STRIPE_PAYMENT_STATUS.CANCELED:
        return "Canceled";
      default:
        return null;
    }
  };

  const message = getSubscriptionMessage();

  return (
    <>
      {message && (
        <div className={`subs-info ${user.subscriptionStatus}`}>
          <h4 className="m-0">{message}</h4>
          {location.pathname !== "/payment" && (
            <Button
              type="primary"
              size="small"
              onClick={() => navigate("/payment")}
            >
              {user?.isTrialPeriodValid ? "Subscribe" : "Update"}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default SubscriptionInfo;
