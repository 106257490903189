import { CustomTable, Searchbar } from "global/components";
import {
  ColumnGraph,
  ImagesSection,
  PricingSection,
  Statistics,
} from "./components";
import "./styles.scss";

import { SearchOutlined } from "@ant-design/icons";
import { Result } from "antd";
import {
  longTailAlternativesColumns,
  popularTagsColumns,
  sampleListingColumns,
  similarShopperSearchesColumns,
} from "./tablesColumns";
import useHome from "./useHome";

const Home = () => {
  const {
    popularTagsDataSource,
    sampleListingDataSource,
    similarShopperSearchesDataSource,
    longTailAlternativesDataSource,
    handleSearch,
    itemListingResult,
    getItemListingApiLoading,
    shippingPriceColumnGraphProps,
    itemPriceDotGraphSeries,
    handleSampleListingTableRowClick,
    images,
    callGetPopularTagsApi,
    callGetLongTailAlternativeTagsApi,
    callSimilarShoppingTagsApi,
    searchValue,
    pricingGraph,
  } = useHome();

  return (
    <div className="dashboard-home-wrapper w-100">
      <Searchbar onSearch={handleSearch} loading={getItemListingApiLoading} />

      {Object.keys(itemListingResult).length === 0 ? (
        <Result
          icon={<SearchOutlined />}
          title="Write your query in the search bar!"
        />
      ) : (
        <>
          <Statistics itemListingResult={itemListingResult} />

          <CustomTable
            className="section-margin-top"
            dataSource={popularTagsDataSource}
            columns={popularTagsColumns}
            tableTitle={"Popular Tags"}
            searchKeys={["tag"]}
            searchPlaceholder={"Search By Tag"}
            rowKey={"tag"}
            paginationMode="backend"
            fetchData={callGetPopularTagsApi}
            initialSearchValue={searchValue}
          />

          <PricingSection
            itemListingResult={itemListingResult}
            itemPriceDotGraphSeries={itemPriceDotGraphSeries}
            pricingGraph={pricingGraph}
          />

          <CustomTable
            className="section-margin-top"
            dataSource={sampleListingDataSource}
            columns={sampleListingColumns}
            tableTitle={"Sample Listing"}
            searchPlaceholder={"Search By Title"}
            searchKeys={["title"]}
            rowKey={"title"}
            handleRowClick={handleSampleListingTableRowClick}
          />

          <ColumnGraph
            title="Shipping Price Distribution"
            series={shippingPriceColumnGraphProps.series}
            xaxis={shippingPriceColumnGraphProps.xaxis}
          />

          <CustomTable
            className="section-margin-top"
            dataSource={similarShopperSearchesDataSource}
            columns={similarShopperSearchesColumns}
            searchPlaceholder={"Search By Keyword"}
            tableTitle={"Similar Shopper Searches"}
            searchKeys={["keyword"]}
            rowKey={"keyword"}
            paginationMode="backend"
            fetchData={callSimilarShoppingTagsApi}
            initialSearchValue={searchValue}
          />

          <CustomTable
            className="section-margin-top"
            dataSource={longTailAlternativesDataSource}
            columns={longTailAlternativesColumns}
            tableTitle={"Long Tail Alternatives"}
            searchPlaceholder={"Search By Keyword"}
            searchKeys={["keyword"]}
            rowKey={"keyword"}
            paginationMode="backend"
            fetchData={callGetLongTailAlternativeTagsApi}
            initialSearchValue={searchValue}
          />
          <ImagesSection images={images} />
        </>
      )}
    </div>
  );
};

export default Home;
