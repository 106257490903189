import { Button, Space } from "antd";
import {
  BUILDINGS_ICON,
  LOCATION_PIN_ICON,
  MAIL_BOX_ICON,
  MAP_PIN_ICON,
  PIN_OUTLINED,
  USER_OUTLINED
} from "assets";
import { CustomInput } from "global/components";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const PersonalDetails = ({ onNextStep, loading, initialValues }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    // Populate each field if initialValues are provided
    Object.entries(initialValues || {}).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [initialValues, setValue]);

  return (
    <form onSubmit={handleSubmit((data) => onNextStep(data))}>
      <p className="subtle-text">Please enter your personal detail</p>
      <Space direction="vertical" className="w-100">
        <CustomInput
          label="Full Name"
          icon={USER_OUTLINED}
          placeholder={"John Doe"}
          error={errors["name"]}
          required
          {...register("name", {
            required: {
              value: true,
              message: "Name is required",
            },
          })}
        />

        <CustomInput
          label="Country"
          type="text"
          icon={PIN_OUTLINED}
          placeholder={"United States"}
          error={errors["country"]}
          required
          {...register("country", {
            required: {
              value: true,
              message: "Country is required",
            },
          })}
        />

        <CustomInput
          label="City"
          type="text"
          icon={LOCATION_PIN_ICON}
          placeholder={"City"}
          error={errors["city"]}
          required
          {...register("city", {
            required: {
              value: true,
              message: "City is required",
            },
          })}
        />

        <CustomInput
          label="Street"
          type="text"
          icon={MAP_PIN_ICON}
          placeholder={"Street"}
          error={errors["street"]}
          {...register("street", {
            required: {
              value: false,
              message: "Street is required",
            },
          })}
        />

        <CustomInput
          label="Postal Code"
          type="text"
          icon={MAIL_BOX_ICON}
          placeholder={"Postal Code"}
          error={errors["postal_code"]}
          required
          {...register("postal_code", {
            required: {
              value: true,
              message: "Postal Code is required",
            },
          })}
        />
        <CustomInput
          label="State"
          type="text"
          icon={BUILDINGS_ICON}
          placeholder={"State"}
          error={errors["state"]}
          {...register("state", {
            required: {
              value: false,
              message: "State is required",
            },
          })}
        />
      </Space>
      <Button
        block
        type="primary"
        loading={loading}
        className="custom-btn"
        htmlType="submit"
      >
        Finish
      </Button>
    </form>
  );
};

export default PersonalDetails;
