import { Layout } from "antd";
import { useScrollToTop } from "hooks";
import { usePageTop } from "hooks";
import { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Header, Sidebar } from "./components";

const { Content } = Layout;

const DashboardLayout = ({ disabled }) => {
  const contentWrapperRef = useRef();
  const isPageTop = usePageTop(contentWrapperRef);
  useScrollToTop(contentWrapperRef);
  return (
    <Layout className={"dashboard-layout-wrapper"}>
      <Sidebar disabled={disabled} />
      <Layout className="dashboard-right">
        <Header isPageTop={isPageTop} />
        <Content className={`content-wrapper`} ref={contentWrapperRef}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
