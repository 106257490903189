import { Button, Space } from "antd";
import { PASSWORD_ICON } from "assets";
import { CustomInput } from "global/components";
import "./styles.scss";
import useResetPassword from "./useResetPassword";

const ResetPassword = () => {
  const { register, errors, handleNavigation, handleUpdatePassword } =
    useResetPassword();

  return (
    <div className="reset-password-rapper">
      <p>Please enter your new password to update your account details</p>
      <form onSubmit={handleUpdatePassword}>
        <Space direction="vertical" className="w-100" size={20}>
          <CustomInput
            label="Password"
            icon={PASSWORD_ICON}
            type="password"
            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            error={errors["password"]}
            {...register("password", {
              required: {
                value: true,
                message: "Password is required",
              },
            })}
          />
          <CustomInput
            label="Confirm Password"
            icon={PASSWORD_ICON}
            type="password"
            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            error={errors["confirmPassword"]}
            {...register("confirmPassword", {
              required: {
                value: true,
                message: "Confirm Password is required",
              },
              validate: (value, formValues) => value === formValues["password"] || 'Passwords dont match',
            })}
          />
        </Space>

        <Button block type="primary" className="custom-btn" htmlType="submit">
          Update Password
        </Button>
        <p className="text-center register-text">
          Already have an account ?{" "}
          <mark
            className="primary-text-lighten-10 font-bold cursor-pointer"
            onClick={handleNavigation("/auth/login")}
          >
            Login
          </mark>
        </p>
      </form>
    </div>
  );
};

export default ResetPassword;
