import React from "react";

const TopTags = ({ topTags = [] }) => {
  return (
    <div className="top-tags-layout">
      <div className="tags-header">
        <p>Top Tags</p>
      </div>

      <div className="tags-content">
        <ol>
          {topTags.map((tag, index) => (
            <li key={index}>
              <p className="m-0">{tag[0]}</p>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default TopTags;
