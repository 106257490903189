import { Button } from "antd";
import { MESSAGE_ICON } from "assets";
import { CustomInput } from "global/components";
import VerificationInput from "react-verification-input";
import "./styles.scss";
import useForgotPassword from "./useForgotPassword";

const ForgotPassword = () => {
  const {
    isEmailSent,
    handleNavigation,
    errors,
    register,
    handleEmailFormSubmit,
    forgotPasswordApiLoading,
    handleCodeChange,
    handleVerifyCode,
    verifyOtpApiLoading,
  } = useForgotPassword();

  const codeVerification = (
    <>
      <p className="line-height-25">
        Please enter code sent to your email{" "}
        <mark className="cursor-pointer primary-text-lighten-10 ">
          test@gmail.com
        </mark>{" "}
        to verify the account details
      </p>
      <VerificationInput
        placeholder="_"
        validChars="A-Za-z0-9!@#\$%\^&*"
        classNames={{
          container: "v-container",
          character: "v-character",
          characterInactive: "character--inactive",
          characterSelected: "character--selected",
        }}
        onChange={handleCodeChange}
      />
      <Button
        block
        type="primary"
        className="custom-btn"
        onClick={handleVerifyCode}
        loading={verifyOtpApiLoading}
      >
        Continue
      </Button>

      <p className="text-center">
        Did not Receive a email?{" "}
        <mark className="cursor-pointer primary-text-color ">Resend</mark>
      </p>
    </>
  );

  const emailView = (
    <>
      <p className="line-height-25">
        Please enter your email and we will send you a code to reset your
        password
      </p>

      <form onSubmit={handleEmailFormSubmit}>
        <CustomInput
          label="Email"
          icon={MESSAGE_ICON}
          placeholder={"example@gmail.com"}
          error={errors["email"]}
          {...register("email", {
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
        />

        <Button
          block
          type="primary"
          className="custom-btn"
          style={{ marginTop: 30 }}
          htmlType="submit"
          loading={forgotPasswordApiLoading}
          error={errors["password"]}
        >
          Continue
        </Button>
      </form>
    </>
  );

  return (
    <div className="forgot-password-wrapper">
      {isEmailSent ? codeVerification : emailView}

      <p className="text-center register-text">
        Already have an account ?{" "}
        <mark
          className="primary-text-lighten-10 font-bold cursor-pointer"
          onClick={handleNavigation("/auth/login")}
        >
          Login
        </mark>
      </p>
    </div>
  );
};

export default ForgotPassword;
