import { meAxiosParams } from "api/axiosHookParams";
import { STRIPE_PAYMENT_STATUS, LOCAL_STORAGE_VALUES } from "constants/common";
import { useAxios } from "hooks";
import { createContext, useCallback, useEffect, useState } from "react";
import axiosInstance from "services/axios";
import refreshToken from "services/axios/refresh";
import { calculateTrialPeriod } from "utils";

// Create a new context for layout management
export const UserContext = createContext();

// Create a provider component to wrap around the app
export const UserProvider = ({ children }) => {
  // Use state to manage active heading and logged in user info

  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const { loading: meApiLoading, result } = useAxios(meAxiosParams);

  const onLoginUser = useCallback((user, meCall = false) => {
    const {
      access_token,
      refresh_token,
      creation_date,
      subscription_status,
      ...rest
    } = user;
    if (!meCall) {
      localStorage.setItem(LOCAL_STORAGE_VALUES.ACCESS_TOKEN, access_token);
      localStorage.setItem(LOCAL_STORAGE_VALUES.REFRESH_TOKEN, refresh_token);
    }

    const trialPeriodDays = parseInt(
      process.env.REACT_APP_TRIAL_PERIOD_DAYS,
      10
    );

    let isTrialPeriodValid = false,
      trialPeriodRemainingDays = 0;

    const isPayed = subscription_status === STRIPE_PAYMENT_STATUS.ACTIVE;
    if (!isPayed) {
      const trialPeriodConfig = calculateTrialPeriod(
        creation_date,
        trialPeriodDays
      );
      isTrialPeriodValid = trialPeriodConfig.isTrialPeriodValid;
      trialPeriodRemainingDays = trialPeriodConfig.trialPeriodRemainingDays;
    }
    const subscriptionStatus =
      subscription_status ||
      (isTrialPeriodValid
        ? STRIPE_PAYMENT_STATUS.TRIAL
        : STRIPE_PAYMENT_STATUS.NO_SUBSCRIPTION);

    const updatedUserInfo = {
      ...rest,
      image_url: rest.image_url
        ? `${process.env.REACT_APP_API_URL}/${rest.image_url}`
        : rest.image_url,
      isTrialPeriodValid,
      trialPeriodRemainingDays,
      subscriptionStatus,
      isPayed,
    };

    setIsAuthenticated(true);
    setUser(updatedUserInfo);
  }, []);

  const onLogoutUser = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.clear();
  }, []);

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 403) {
        setIsAuthenticated(false);
        return Promise.reject(error);
      }

      const originalRequest = error.config;
      const refresh = localStorage.getItem(LOCAL_STORAGE_VALUES.REFRESH_TOKEN);

      if (
        error?.response?.status === 401 &&
        refresh &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        try {
          const access_token = await refreshToken();
          originalRequest.headers["x-access-token"] = access_token;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // dispatch logout user from store
          onLogoutUser();
          return Promise.reject(refreshError);
        }
      }

      if (error?.response?.status === 401 && !refresh) {
        // dispatch logout user from store
        onLogoutUser();
      }

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (result?.status === 200) {
      onLoginUser(result?.user_info, true); // me call
    } else if (result !== null && meApiLoading === false) {
      setIsAuthenticated(false);
    }
  }, [result, meApiLoading, onLoginUser]);

  return (
    // Provide the state values and state updater functions to children components
    <UserContext.Provider
      value={{
        setUser,
        user,
        isAuthenticated,
        setIsAuthenticated,
        onLoginUser,
        onLogoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
