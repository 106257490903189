import { Col, Form, Row, Spin } from "antd";
import { CardFormCalculator, CardShowCalculation } from "./Component";
import { CONVERT_ICON } from "assets";
import "./styles.scss";
import useDashboardCalculator from "./useDashboardCalculator";
const DashboardCalculator = () => {
  const {
    form,
    handleCalculateProfit,
    totalFees,
    totalEstimatedMargin,
    feeAndTaxes,
    estimatedProfit,
    calculateProfitApiResult,
    loading = true,
  } = useDashboardCalculator();

  return (
    <Form form={form} layout={"vertical"} onFinish={handleCalculateProfit}>
      <Row gutter={[20, 30]}>
        <Col xs={24} sm={24} lg={11}>
          <CardFormCalculator
            calculateProfitApiResult={calculateProfitApiResult}
          />
        </Col>

        <Col xs={24} sm={24} lg={2}>
          <div className="icon-convert">
            {loading ? (
              <Spin />
            ) : (
              <img src={CONVERT_ICON} alt="" onClick={form.submit} />
            )}
          </div>
        </Col>

        <Col xs={24} sm={24} lg={11}>
          <div className="calculation-third-col">
            <CardShowCalculation
              data={feeAndTaxes}
              title={"Fees and Taxes"}
              hasRadio={true}
              total={totalFees}
            />
            <CardShowCalculation
              data={estimatedProfit}
              title={"Estimated Profit"}
              total={totalEstimatedMargin}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default DashboardCalculator;
