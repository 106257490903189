const API_ENDPOINTS = {
  REGISTER: (email, password, name, country, postal_code, street, state) =>
    `signUp?email=${email}&password=${password}&name=${name}&country=${country}&is_subscribed=N&postal_code=${postal_code}&street=${street}&state=${state}`,
  ME: "me",

  LOGIN: (email, password) => `signIn?email=${email}&password=${password}`,
  FORGOT_PASSWORD: (email = "") => `forgotPassword?email=${email}`,
  DELETE_ACCOUNT: (email = "") => `deleteAccount?email=${email}`,
  UPDATE_PROFILE: "updateProfile",
  CHANGE_PASSWORD: (email, password) => `changePassword`,
  GET_CALENDAR_HOLIDAYS: "calenderHolidays",
  GET_LISTING: (keyword) => `getListing${keyword ? "/" + keyword : ""}`,
  // GET_SINGLE_LISTING: (id) => `getSingleListing/${id}`,
  GET_SINGLE_LISTING: (id) => `getSingleListing`,
  CALCULATE_PROFIT: (params) =>
    `calculateProfit?${new URLSearchParams(params).toString()}`,
  GET_HISTORY: "getHistory",
  VERIFY_CODE: "verifyCode",
  RESET_PASSWORD: "changePasswordByVerifyingOtp",
  GET_POPULAR_TAGS: "nextPopularTags",
  GET_LONG_TAIL_ALTERNATIVE_TAGS: "nextLongTailAlternativeTags",
  GET_SIMILAR_SHOPPING_TAGS: "nextSimilarShopperTags",
  // strioe
  ADD_STRIPE_CUSTOMER: "add_stripe_customer",
  CREATE_SUBSCRIPTION: "create-subscription",
  CANCEL_SUBSCRIPTION: "cancel-subscription",
  REGISTER_FOR_NEWS: "registerForNewsAndUpdates",
  CHECK_USER_EXIST: "checkUserAlreadyExist",
};

export default API_ENDPOINTS;
