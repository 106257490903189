import { CheckCircleFilled } from "@ant-design/icons";
import { registerApiAxiosParams } from "api/axiosHookParams";
import API_ENDPOINTS from "api/endpoints";
import { useAxios } from "hooks";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PersonalDetails, VerifyEmail, VerifyPassword } from "./components";
import useUser from "context/User/useUser";

const useRegister = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({}); // Initialized as empty object
  const { onLoginUser } = useUser();

  const { callAxios: callRegisterUserApi, loading } = useAxios(
    registerApiAxiosParams
  );

  const handleNavigation = useCallback(
    (path) => () => navigate(path),
    [navigate]
  );

  const registerUser = useCallback(
    async (finalFormValues) => {
      const { email, password, name, country, postal_code, street, state } =
        finalFormValues;
      const result = await callRegisterUserApi({
        url: API_ENDPOINTS.REGISTER(
          email,
          password,
          name,
          country,
          postal_code,
          street,
          state
        ),
      });
      if (result?.status === 200) {
        onLoginUser(result?.user_info);
      }
    },
    [callRegisterUserApi, onLoginUser]
  );

  const handleNextStep = useCallback(
    (values) => {
      const newFormValues = { ...formValues, ...values };
      if (activeStep === 2) {
        // Assuming 3 steps: 0, 1, 2
        registerUser(newFormValues);
      } else {
        setFormValues(newFormValues);
        setActiveStep((currentStep) => currentStep + 1);
      }
    },
    [activeStep, formValues, registerUser]
  );

  const goBack = useCallback(() => {
    setActiveStep((currentStep) => Math.max(currentStep - 1, 0));
  }, []);

  const createStepIcon = (step) =>
    activeStep > step ? (
      <CheckCircleFilled style={{ fontSize: 30 }} />
    ) : (
      <div className={`dot ${activeStep === step ? "active" : ""}`} />
    );

  const stepComponents = [
    {
      icon: createStepIcon(0),
      content: (
        <VerifyEmail
          onNextStep={handleNextStep}
          initialValues={{ email: formValues.email }}
        />
      ),
    },
    {
      icon: createStepIcon(1),
      content: (
        <VerifyPassword
          onNextStep={handleNextStep}
          initialValues={{
            password: formValues.password,
            confirmPassword: formValues.confirmPassword,
          }}
        />
      ),
    },
    {
      icon: createStepIcon(2),
      content: (
        <PersonalDetails
          onNextStep={handleNextStep}
          loading={loading}
          initialValues={formValues}
        />
      ),
    },
  ];

  return { handleNavigation, stepComponents, activeStep, goBack };
};

export default useRegister;
