import { Col, Row } from "antd";
import { HelpCard, InfoCard } from "./Component";

import { PHONE_ICON, MAP_ICON, MAIL_ICON } from "assets";
import "./styles.scss";

const Help = () => {
  return (
    <div className="help-layout">
      <Row gutter={[30, 20]}>
        <Col xs={24} md={14}>
          <div className="help-content">
            <div className="help-heading">
              <h3>How Can We Help You?</h3>
            </div>
            <div className="help-desc">
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus massa odio, iaculis ut rutrum quis, consequat
                imperdiet nulla. Ut euismod mauris vel metus ultrices maximus.
                Donec congue nibh ac fermentum maximus.
              </p> */}
            </div>
            <div className="help-info">
              <Row gutter={[30, 20]}>
                <Col sm={24}>
                  <InfoCard
                    icon={MAP_ICON}
                    data={"Our Office"}
                    title={"12345, Location, City, Country"}
                  />
                </Col>
                <Col sm={24}>
                  <InfoCard
                    icon={MAIL_ICON}
                    data={"Email"}
                    title={"eprimedata123@gmail.com"}
                  />
                </Col>
                <Col sm={24}>
                  <InfoCard
                    icon={PHONE_ICON}
                    data={"Phone"}
                    title={"+123456789, +1456785443"}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col xs={24} md={10}>
          <HelpCard />
        </Col>
      </Row>
    </div>
  );
};

export default Help;
