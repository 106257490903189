import { Button, Col, Row } from "antd";
import {
  CALENDER_PROFILE_ICON,
  GLOBE_ICON,
  MESSAGE_ICON,
  PASSWORD_ICON,
  PERSON_ICON,
  PHONE_ICON,
} from "assets";
import { CustomInput } from "global/components";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const InfoSection = ({
  handleUpdateProfile,
  updateProfileApiLoading,
  user,
}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    reset({
      email: user?.email,
      phone: user?.contact_no,
      country: user?.country,
      date_of_birth: user?.date_of_birth,
      name: user?.name,
    });
  }, [user, reset]);

  return (
    <div className="section-margin-top info-section">
      <div className="heading">
        <h4>Your Profile</h4>
      </div>
      <form className="forms" onSubmit={handleSubmit(handleUpdateProfile)}>
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <CustomInput
              label="Full Name"
              icon={PERSON_ICON}
              placeholder={"Robert"}
              error={errors["name"]}
              {...register("name", {
                required: {
                  value: true,
                  message: "Name is required",
                },
              })}
            />
          </Col>
          <Col xs={24} md={12}>
            <CustomInput
              label="Email Address"
              icon={MESSAGE_ICON}
              placeholder={"example@gmail.com"}
              disabled
              error={errors["email"]}
              {...register("email", {
                required: {
                  value: true,
                  message: "Email is required",
                },
              })}
            />
          </Col>
          <Col xs={24} md={12}>
            <CustomInput
              label="Phone"
              icon={PHONE_ICON}
              placeholder={"0000000000"}
              error={errors["phone"]}
              {...register("phone", {
                required: {
                  value: false,
                  message: "Phone number is required",
                },
              })}
            />
          </Col>
          <Col xs={24} md={12} className="password-field-col">
            <CustomInput
              label="Password"
              icon={PASSWORD_ICON}
              type="password"
              placeholder={"********"}
              disabled
              {...register("password", {
                required: {
                  value: false,
                },
              })}
            />
            <Button
              type="primary"
              className="change-password-button"
              onClick={() => navigate("/app/change-password")}
            >
              Change
            </Button>
          </Col>
          <Col xs={24} md={12}>
            <CustomInput
              label="Date of Birth"
              icon={CALENDER_PROFILE_ICON}
              placeholder={"2/2/2"}
              type="date"
              {...register("date_of_birth", {
                required: {
                  value: false,
                  message: "Date of birth is required",
                },
              })}
            />
          </Col>
          
          <Col xs={24} md={12}>
            <CustomInput
              label="Country"
              icon={GLOBE_ICON}
              placeholder={"Pakistan"}
              error={errors["country"]}
              {...register("country", {
                required: {
                  value: true,
                  message: "Country is required",
                },
              })}
            />
          </Col>
        </Row>
        <div className="delete-button">
          <Button
            htmlType="submit"
            type="primary"
            loading={updateProfileApiLoading}
          >
            Update Profile
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InfoSection;
