import React from "react";
import Chart from "react-apexcharts";

const GroupedColumnGraph = ({ data }) => {

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: data.priceRanges,
    },
    // yaxis: {
    //   title: {
    //     text: "Listings",
    //   },
    // },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " listings";
        },
      },
    },
    colors: [
      "#3626a7", // Original Color
      "#271c80", // Darker Shade
      "#5843d1", // Lighter Shade
      "#4e44a1", // Desaturated Shade
    ],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 10,
    },
  };

  const series = data.series;

  // Render the ApexChart component from react-apexcharts
  return (
    <div className="card-layout">
      <div className="card-layout-header">
        <h3 className="card-heading">{"Pricing"}</h3>
      </div>
      <div id="chart">
        <Chart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
};

export default GroupedColumnGraph;
