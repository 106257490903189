import { LogoutOutlined, ProfileOutlined } from "@ant-design/icons";
import useUser from "context/User/useUser";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const useHeader = () => {
  const navigate = useNavigate();
  const { onLogoutUser, user } = useUser();

  const handleNavigation = useCallback(
    (route) => () => navigate(`/app/${route}`),
    [navigate]
  );

  const handleLogout = useCallback(() => {
    onLogoutUser();
  }, [onLogoutUser]);

  const userOptions = useMemo(
    () => [
      {
        label: "Profile",
        onClick: handleNavigation("profile"),
        icon: <ProfileOutlined />,
        key: 1,
      },
      {
        type: "divider",
      },
      {
        label: "Logout",
        onClick: handleLogout,
        icon: <LogoutOutlined />,
        key: 2,
      },
    ],
    [handleNavigation, handleLogout]
  );

  return { userOptions, user };
};

export default useHeader;
