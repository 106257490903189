import { Button } from "antd";
import { registerForNewsAxiosParams } from "api/axiosHookParams";
import { useAxios } from "hooks";
import { useState } from "react";

const HelpCard = () => {

  const [email, setEmail] = useState('')

  const {callAxios , loading} = useAxios(registerForNewsAxiosParams)

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSendEmail = async () => {
    const response = await callAxios({
      data : {
        email
      }
    })
    if(response.status === 200) {
      setEmail('')
    }
  }

  return (
    <div className="help-card">
      <div className="help-card-header">
        <h4>Help</h4>
        <p>
          You can write us your query and we will get back to you as soon as
          possible
        </p>
      </div>

      <div className="help-card-content">
        <div className="help-card-box">
          <textarea value={email} onChange={onEmailChange} placeholder="Whats on your mind" />
        </div>
        <div className="help-card-button">
          <Button loading={loading} onClick={handleSendEmail}  block type="primary" className="font-bold text-18">
            SEND EMAIL
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
