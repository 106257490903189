import { Button } from "antd";
import { CAMERA_ICON } from "assets";
import { STRIPE_PAYMENT_STATUS } from "constants/common";
import useUser from "context/User/useUser";

const HeaderSection = ({
  uploadInputRef,
  handleFileSelect,
  handleAccountDelete,
  deleteAccountApiLoading,
  cancelSubscriptionApiLoading,
  handleCancelSubscription,
  selectedImageUrl,
}) => {
  const { user } = useUser();

  const showCancelSubscriptionButton =
    user?.subscriptionStatus &&
    user.subscriptionStatus !== STRIPE_PAYMENT_STATUS.NO_SUBSCRIPTION &&
    !user.isTrialPeriodValid;

  return (
    <>
      <input
        ref={uploadInputRef}
        className="d-none"
        onChange={handleFileSelect}
        type="file"
      />
      <div className="profile-header">
        <div className="profile-header-left">
          <div className="profile-avatar-wrapper">
            <img
              src={CAMERA_ICON}
              alt=""
              className="picker-icon"
              onClick={() => uploadInputRef?.current?.click()}
            />
            {selectedImageUrl && <img alt="profile" src={selectedImageUrl} />}
          </div>
          <div className="user-info">
            <p className="m-0 username">{user?.name}</p>
            <p className="m-0">{user?.email || "usmanmalik"}</p>
          </div>
        </div>
        <div className="profile-header-right">
          <Button
            loading={deleteAccountApiLoading}
            type="primary"
            danger
            onClick={handleAccountDelete}
          >
            Delete My Account
          </Button>
          {showCancelSubscriptionButton && (
            <Button
              onClick={handleCancelSubscription}
              loading={cancelSubscriptionApiLoading}
            >
              Cancel Subscription
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderSection;
