import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import { Button, Divider, Radio, Space } from "antd";
import "./styles.scss";
import useCheckoutForm from "./useCheckoutForm";

// Load stripe with your test key from .env

const CheckoutForm = () => {
  const {
    handleSubmit,
    stripe,
    messageContext,
    stripeBasicPlanKey,
    stripeProPlanKey,
    setSelectedPriceId,
    selectedPriceId,
    loading,
  } = useCheckoutForm();

  return (
    <>
      {messageContext}
      <div className="payment-form-container">
        <form onSubmit={handleSubmit} className="payment-form">
          <div className="field-container">
            <label>Packages</label>
            <Radio.Group
              onChange={(e) => setSelectedPriceId(e.target.value)}
              value={selectedPriceId}
            >
              <Space align="end">
                <Radio value={stripeBasicPlanKey}>{"200$"}</Radio>
                <Radio value={stripeProPlanKey}>{"300$"}</Radio>
              </Space>
            </Radio.Group>
          </div>
          <Divider style={{ marginTop: 0 }} />
          <div className="field-container">
            <label>Card Number</label>
            <CardNumberElement />
          </div>
          <div className="field-container">
            <label>Card Expiry</label>
            <CardExpiryElement />
          </div>
          <div className="field-container">
            <label>CVV</label>
            <CardCvcElement />
          </div>
          <Button
            block
            size="large"
            htmlType="submit"
            type="primary"
            disabled={!stripe}
            loading={loading}
          >
            Pay
          </Button>
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;
