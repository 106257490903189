import { Spin } from "antd";
import { COMPARISON_SEARCH_ICON } from "assets";
import { useLaterEffect } from "hooks";
import { useDebounce } from "hooks";
import { useState } from "react";
import { ReactSVG } from "react-svg";
const CompSearchBar = ({ placeholder = "", onSearch, loading }) => {
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchText = useDebounce(searchText, 300);

  useLaterEffect(() => {
    searchText !== null && onSearch(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <div className="comp-search-bar">
      <input
        placeholder={placeholder}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {loading ? (
        <Spin size="small"/>
      ) : (
        <ReactSVG src={COMPARISON_SEARCH_ICON} alt="" className="search-icon" />
      )}
    </div>
  );
};

export default CompSearchBar;
